import { AfterViewInit, Directive, EventEmitter, Output } from '@angular/core';
import { AccordionTab } from 'primeng/accordion';

@Directive({
    selector: '[pluAccordionTabHeaderHeight]',
    standalone: true
})
export class AccordionTabHeaderHeightDirective implements AfterViewInit {

    @Output('pluAccordionTabHeaderHeight') height: EventEmitter<number> = new EventEmitter<number>();
    constructor(private readonly accordionTab: AccordionTab) {}

    ngAfterViewInit(): void {
        const accordionTabHeader = Array.from(this.accordionTab.el.nativeElement.getElementsByClassName('p-accordion-header'))?.[0];
        this.height.emit(accordionTabHeader ? (accordionTabHeader as HTMLDivElement).offsetHeight : 0);
    }
}
