import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({ providedIn: 'root' })
export class FileDownloadService {

    constructor(private readonly messageService: MessageService) {}

    download(fileUrl: string, fileName: string): void {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = fileUrl;
        a.download = fileName;
        a.click();
        const messageService = this.messageService;
        a.onerror = function() {
            messageService.add({ severity: 'error', summary: 'File download error', detail: 'An error occurred while downloading the file.!' });
        }
        document.body.removeChild(a);
    }
}
