import { PortCallExcelHeaders } from '@port-line-up/shared/util';
import { User } from '@port-line-up/shared/data-access';
import { ExcelHeadersResolver } from '../../excel-headers-resolver';

export class ExcelHeadersForTemplateGacUserResolver extends ExcelHeadersResolver {
    constructor(private readonly user: User) {
        super();
    }

    override canResolve(): boolean {
        return this.user.isGacUser;
    }

    override resolve(): PortCallExcelHeaders[] {
        return [
            'No.',
            'Vessel Name',
            'Imo Number',
            'Vessel Type',
            'Voyage Number',
            'Status',
            'Port Name',
            'Port Code',
            'Charterer',
            'Charterer Code',
            'Shipper',
            'Shipper Code',
            'Shipping Agent',
            'Shipping Agent Code',
            'Terminal',
            'Berth',
            'Berth Priority',
            'ETA',
            'ETA IHS',
            'ATA',
            'ETB',
            'ATB',
            'ECC',
            'ACC',
            'ETC',
            'ATC',
            'ETD',
            'ATD',
            'Quantity',
            'Notes',
            'Dwt',
            'Delay',
            'Next Destination',
            'Cargo',
            'Cargo Description',
            'Cargo Rate',
            'UOM (CR)',
            'Operation',
            'UOM (QT)'
        ];
    }
}
