import {
    Component, computed, DestroyRef, inject,
    input,
    Input,
    InputSignal,
    OnInit, signal,
    TemplateRef,
    ViewChild,
    WritableSignal
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedViewTableComponent } from './components/table/table.component';
import { ApiResponse, ApiResponseType, Coordinates, ExcelExportLog, LogService, Port, PortCall, UIStore } from '@port-line-up/shared/data-access';
import { combineLatest, concatMap, first, map, Observable, withLatestFrom } from 'rxjs';
import { AccordionModule } from 'primeng/accordion';
import {
    ChangeMessageComponent,
    FilterChipsService, MapComponent, PortCallDelayedService, PresentationTableSettingsMenuComponent,
    SignalRChangeHandlerService,
    SignalRDataUpdateService, TableFilteredService
} from '@port-line-up/shared/feature-presentation-table';
import { ButtonModule } from 'primeng/button';
import {
    AccordionHeaderTemplate, AccordionTabHeaderHeightDirective,
    GridFilterItem,
    GridFilterReactiveService,
    PortCallExportService
} from '@port-line-up/shared/util';
import { SharedViewStore } from '../shared-view.store';
import { BreadcrumbComponent, BreadcrumbItem } from '@port-line-up/shared/ui/breadcrumb';
import { ScopedSpinnerComponent } from '@port-line-up/shared/ui/scoped-spinner';
import { MessageService } from 'primeng/api';
import { SailedTableComponent } from './components/sailed-table/sailed-table.component';
import { ExcelExportFacadeService } from '@port-line-up/shared/feature-user-specific-rules';
import { InputSwitchModule, InputSwitchChangeEvent } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';
import { Asset } from './models/assets';
import { DialogService } from 'primeng/dynamicdialog';
import { AssetsDownloadComponent } from './components/assets-download/assets-download.component';
import { SharedReportComponent } from './components/shared-report/shared-report.component';
import { GridFilterChipsComponent } from '@port-line-up/shared/ui/grid-filters-chips';
import { DisclaimerComponent } from '@port-line-up/shared/ui/disclaimer';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'plu-shared-view',
    standalone: true,
    imports: [
        CommonModule,
        SharedViewTableComponent,
        AccordionModule,
        ButtonModule,
        BreadcrumbComponent,
        ChangeMessageComponent,
        MapComponent,
        ScopedSpinnerComponent,
        SailedTableComponent,
        InputSwitchModule,
        FormsModule,
        SharedReportComponent,
        PresentationTableSettingsMenuComponent,
        GridFilterChipsComponent,
        AccordionTabHeaderHeightDirective,
        DisclaimerComponent,
    ],
    templateUrl: './shared-view.component.html',
    providers: [{ provide: AccordionHeaderTemplate, useExisting: SharedViewComponent }, SignalRDataUpdateService, PortCallDelayedService],
})
export class SharedViewComponent implements OnInit, AccordionHeaderTemplate {
    @Input() port!: Port;
    @Input() assets!: Asset[];
    @Input() showReport!: boolean;
    accordionHeaderHeight: InputSignal<number> = input.required<number>();

    breadcrumbItem!: BreadcrumbItem;

    lastUpdate$: Observable<Date> = this.sharedViewStore.lastUpdate$;
    portCalls$: Observable<PortCall[]> = this.sharedViewStore.portCalls$;
    initialFilters$: Observable<GridFilterItem> = this.sharedViewStore.filters$;
    tableFilteredPortCalls$ = this.tableFilteredService.portCalls$;

    @ViewChild('headerTemplate', { static: true }) headerTemplate!: TemplateRef<any>;

    accordionTabHeaderHeight: WritableSignal<number> = signal<number>(0)

    uiStore = inject(UIStore);

    tableHeightOffset = computed(() => {
        return this.uiStore.headerHeight() + this.accordionTabHeaderHeight() + this.accordionHeaderHeight() - this.uiStore.sharedView().mapHeight
    })

    getSharedViewMapHeight(): string {
        return this.uiStore.sharedView().mapHeight+ 'px';
    }

    get shouldOpenMap$(): Observable<boolean> {
        return combineLatest([this.tableFilteredService.isMapOpened$, this.tableFilteredService.portCalls$]).pipe(
            map(([isMapOpened, portCalls]) => isMapOpened && portCalls.length > 0)
        );
    }

    get shouldOpenReport$(): Observable<boolean> {
        return combineLatest([this.tableFilteredService.isReportOpened$, this.tableFilteredService.portCalls$]).pipe(
            map(([isReportOpened, portCalls]) => isReportOpened && portCalls.length > 0)
        );
    }

    private readonly destroyRef = inject(DestroyRef);

    constructor(
        private readonly signalRDataUpdateService: SignalRDataUpdateService,
        private readonly signalRChangeHandler: SignalRChangeHandlerService,
        public sharedViewStore: SharedViewStore,
        public tableFilteredService: TableFilteredService,
        private readonly portCallExportService: PortCallExportService,
        public filterChipsService: FilterChipsService,
        private readonly gridFilterReactiveService: GridFilterReactiveService,
        private readonly messageService: MessageService,
        private readonly excelExportFacade: ExcelExportFacadeService,
        private readonly dialogService: DialogService,
        private readonly portCallDelayedService: PortCallDelayedService,
        private readonly logService: LogService
    ) {}

    ngOnInit(): void {
        this.breadcrumbItem = this.createBreadcrumbItem(this.port);
        this.signalRDataUpdateService.subscribeToPortUpdate();
        this.signalRChangeHandler.resetChangesAfter(10);
        this.subscribeToApiResponse();
    }

    clearAllFilters(event: Event): void {
        event.stopPropagation();
        this.gridFilterReactiveService.clearBy(this.port.id).subscribe();
    }

    clearFilter(filterId: string): void {
        this.gridFilterReactiveService.clearFilter(this.port.id, filterId).subscribe();
    }

    refresh(event: Event) {
        event.stopPropagation();
        this.sharedViewStore.getPortCalls();
        this.sharedViewStore.getSailedPortCalls();
    }

    export(event: Event) {
        event.stopPropagation();
        this.portCalls$.pipe(first(), map((portCalls) => {
            const fileName = `${this.port.name}_export_${new Date().getTime()}`;
            this.portCallExportService.exportExcel(this.excelExportFacade.getExcelColumns(portCalls), fileName, this.excelExportFacade.getExcelHeaders());
        }), withLatestFrom(this.sharedViewStore.id$), concatMap(([,sharedViewId]) => {
            const excelExportLog = new ExcelExportLog();
            excelExportLog.portId = this.port.id;
            excelExportLog.portCountryName = this.port.countryName;
            excelExportLog.unLoCode = this.port.unloCode;
            excelExportLog.portName = this.port.name;
            excelExportLog.sharedViewId = sharedViewId;
            return this.logService.logExcelExportAnonymous(excelExportLog);
        })).subscribe();
    }

    toggleMap(event: InputSwitchChangeEvent): void {
        event.originalEvent.stopPropagation();
        this.tableFilteredService.toggleMap();
    }

    toggleReport(event: InputSwitchChangeEvent): void{
        event.originalEvent.stopPropagation();
        this.tableFilteredService.toggleReport();
    }

    getPortCordinates(): Coordinates | null {
        return this.port.latitude && this.port.longitude ? new Coordinates(this.port.latitude, this.port.longitude) : null;
    }

    openAssetDownloadModal(event: MouseEvent): void {
        event.stopPropagation();

        this.dialogService.open(AssetsDownloadComponent, {
            header: 'Documents',
            width: '500px',
            height: '400px',
            data: {
                assets: this.assets
            },
        });
    }

    toggleDelayedVessels(event: InputSwitchChangeEvent): void {
        event.originalEvent.stopPropagation();
        this.portCallDelayedService.toggleMarkDelayedVessels();
    }

    setAccordionTabHeaderHeight(accordionTabHeaderHeight: number): void {
        this.accordionTabHeaderHeight.set(accordionTabHeaderHeight);
    }

    private subscribeToApiResponse(): void {
        this.sharedViewStore.apiResponse$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((apiResponse: ApiResponse | null) => {
            if (apiResponse && apiResponse.type === ApiResponseType.Success) {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: apiResponse.message });
            }
        });
    }

    private createBreadcrumbItem(port: Port): BreadcrumbItem {
        return new BreadcrumbItem('ports', 'Ports').appendNext(new BreadcrumbItem(port.name, port.name));
    }
}
