import { ChangeDetectionStrategy, Component, DestroyRef, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { DataUpdateState, DataUpdateStore } from '../../services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'plu-change-message',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './change-message.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeMessageComponent {
    private readonly destroyRef = inject(DestroyRef);
    private readonly dataChangedMessageSubject = new BehaviorSubject<ChangeMessage>({ dataChangeMessage: '', dataAddedMessage: '', dataDeletedMessage: '', dataSailedMessage: ''});
    protected dataChangedMessage$ = this.dataChangedMessageSubject.asObservable();

    constructor(store: DataUpdateStore<DataUpdateState>) {
        combineLatest([store.changedPortCalls$, store.addedPortCalls$, store.deletedPortCalls$, store.sailedPortCallChanges$])
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(([changePortCalls, addedPortCalls, deletedPortCalls, sailedPortCallChanges]) => {
                const message = {} as ChangeMessage;
                message.dataChangeMessage = changePortCalls.size > 0 ? `${changePortCalls.size} port calls changed` : '';
                message.dataAddedMessage = addedPortCalls.size > 0 ? 'Port call data where added.' : '';
                message.dataDeletedMessage = deletedPortCalls.size > 0 ? `${deletedPortCalls.size} port calls deleted.` : '';
                message.dataSailedMessage = sailedPortCallChanges.size > 0 ? `${sailedPortCallChanges.size} vessels sailed.` : '';

                this.dataChangedMessageSubject.next(message);
            });
    }
}

interface ChangeMessage {
    dataChangeMessage: string;
    dataAddedMessage: string;
    dataDeletedMessage: string;
    dataSailedMessage: string;
}
