import { ChangeDetectionStrategy, Component, inject, Input, input, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Port, PortService, PublicHoliday, ScheduledMaintenance } from '@port-line-up/shared/data-access';
import { forkJoin } from 'rxjs';
import { PortCallPresentation, ReportComponent } from '@port-line-up/shared/feature-presentation-table';

@Component({
    selector: 'plu-shared-report',
    standalone: true,
    imports: [CommonModule, ReportComponent],
    templateUrl: './shared-report.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedReportComponent {
    @Input({ required: true }) set port(port: Port) {
        this._port = port;
        this.getPortDetailsData();
    }
    get port(): Port {
        return this._port;
    }

    token = input.required<string | null>();
    portCalls = input.required<PortCallPresentation[]>();

    upcomingPublicHolidays = signal<PublicHoliday[]>([]);
    scheduledMaintenance = signal<ScheduledMaintenance[]>([]);

    private _port: Port = new Port();
    private readonly portService = inject(PortService);

    private getPortDetailsData() {
        const token = this.token();
        if(!token) {
            return;
        }
        forkJoin([
            this.portService.getUpcomingPublicHolidaysForPortReportSharedView(this.port.id, token),
            this.portService.getScheduledMaintenanceForPortReportSharedView(this.port.id, token),
        ]).subscribe(([publicHolidays, scheduledMaintenances]) => {
            this.upcomingPublicHolidays.set(publicHolidays.items);
            this.scheduledMaintenance.set(scheduledMaintenances.items);
        });
    }
}
