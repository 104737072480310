import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FilterItem } from '../models';
import { APP_ENVIRONMENT, LookupItem } from '@port-line-up/shared/util';
import { ExpressionOperator } from '../enums/expression-operator';

@Injectable({ providedIn: 'root' })
export class CountryService {
    private readonly baseUrl = `${this.appEnvironment.baseApiUrl}/country`;

    constructor(private readonly http: HttpClient, @Inject(APP_ENVIRONMENT)
    private readonly appEnvironment: any) {}

    getLookupsBy(name: string, pageSize = 10): Observable<LookupItem[]> {
        const queryString = FilterItem.create('name', name, ExpressionOperator.StartsWith).getHttpParams(0).set('pageSize', pageSize);
        return this.http.get<LookupItem[]>(`${this.baseUrl}/lookup/?${queryString}`);
    }
}
