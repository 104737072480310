/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TableSortService {
    public defaultSort(value1: unknown, value2: unknown, order: number): number {
        let result = null;

        if (value1 == null && value2 != null) result = -1;
        else if (value1 != null && value2 == null) result = 1;
        else if (value1 == null && value2 == null) result = 0;
        else if (typeof value1 === 'string' && typeof value2 === 'string') result = value1.localeCompare(value2);
        else if (typeof value1 === 'number' && typeof value2 === 'number') {
            if (value1 < value2) {
                result = -1;
            } else if (value1 > value2) {
                result = 1;
            } else {
                result = 0;
            }
        }

        return order * (result ?? 1);
    }

    public getValues(data1: any, data2: any, field: any): [value1: unknown, value2: unknown] {
        let value1 = null;
        let value2 = null;
        switch (field) {
            case 'ata':
                value1 = data1.ata ?? data1.eta ?? data1.ataihs;
                value2 = data2.ata ?? data2.eta ?? data2.ataihs;
                break;
            case 'atb':
                value1 = data1.atb ?? data1.etb ?? data1.atbihs;
                value2 = data2.atb ?? data2.etb ?? data2.atbihs;
                break;
            case 'atc':
                value1 = data1.atc ?? data1.etc;
                value2 = data2.atc ?? data2.etc;
                break;
            case 'atd':
                value1 = data1.atd ?? data1.etd ?? data1.atdihs;
                value2 = data2.atd ?? data2.etd ?? data2.atdihs;
                break;
            case 'acc':
                value1 = data1.acc ?? data1.ecc;
                value2 = data2.acc ?? data2.ecc;
                break;
            default:
                value1 = data1[field];
                value2 = data2[field];
        }

        return [value1, value2];
    }
}
