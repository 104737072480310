import { Lookup } from '@port-line-up/shared/util';

export class Port {
    id = '';
    name = '';
    countryName? = '';
    countryId? = '';
    isSupported = false;
    unloCode = '';
    latitude = 0;
    longitude = 0;
    generalRemarks = '';
}

export class PortLookup implements Lookup {
    constructor(public readonly id: string, public readonly displayText: string, public readonly countryName: string) {}

    static create(id: string, displayText: string, countryName: string): PortLookup {
        return new PortLookup(id, displayText, countryName);
    }

    /**
     * 
     * @param id string | null
     * @param displayText string
     * @param countryName string
     * @returns PortLookup if id is not null, otherwise null
     */
    static createIfValid(id: string | null, displayText: string, countryName: string): PortLookup | null {
        return id ? PortLookup.create(id, displayText, countryName) : null;
    }
}
