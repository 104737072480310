import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';


@Injectable({providedIn: 'root'})
export class ActionPendingInterceptor {
    private readonly pendingSubject = new BehaviorSubject<boolean>(false);
    public isPending$ = this.pendingSubject.asObservable();
    private pendingCount = 0;
    private get nonePending() { return this.pendingCount === 0; }
    private readonly isFunction = (type: any) => (typeof (type) === 'function');

    public createInterceptor<T>(obj: T): T {
        const handler = {
            get: (target: any, name: any, receiver: any) => this.handleGet(target, name, receiver)
        };
        return new Proxy(obj, handler);
    }

    public createFunctionInterceptor(func: any) {
        return new Proxy(func, {
            apply: (targetFunc, thisArg, argumentsList) => this.handleApply(targetFunc, thisArg, argumentsList)
        });
    }

    private start() {
        if (this.nonePending) { this.pendingSubject.next(true); }
        this.pendingCount++;
    }

    private stop() {
        if (!this.nonePending) { this.pendingCount--; }
        if (this.nonePending) { this.pendingSubject.next(false); }
    }

    private handleGet(target: any, name: any, receiver: any) {
        const property = Reflect.get(target, name, receiver);
        return this.isFunction(target[name]) ? this.createFunctionInterceptor(property) : property;
    }

    private handleApply(targetFunc: any, thisArg: any, argumentsList: any) {
        this.start();
        const result = this.execute(targetFunc, thisArg, argumentsList);
        return this.handleResult(result);
    }

    private execute(targetFunc: any, thisArg: any, argumentsList: any) {
        let result;
        try {
            result = targetFunc.apply(thisArg, argumentsList);
        } catch (error) {
            this.stop();
            throw error;
        }
        return result;
    }

    private handleResult(result: any) {
        if (!result) {
            this.stop();
            return result;
        }
        const observable = result as Observable<any>;
        if (observable.pipe) {
            return observable.pipe(finalize(() => this.stop()));
        }
        const promise = result as Promise<any>;
        if ((promise as any).finally) {
            return (promise as any).finally(() => this.stop());
        }
        this.stop();
        return result;
    }
}

