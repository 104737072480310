export interface PortCall {
    id: string;
    imoNumber: string;
    priority: number;
    vesselName: string;
    vesselId?: string;
    voyageNumber: string;
    vesselStatus: string | null;
    vesselStatusId: string | null;
    vesselType: string;
    gacJobNumber: string;
    unlocode: string;
    portName: string;
    portId: string;
    portCountryName: string;
    chartererCode: string;
    charterer: string;
    shipperCode: string;
    shipper: string;
    shippingAgentCode: string;
    shippingAgent: string;
    terminalName: string;
    terminalId: string;
    berthName: string;
    berthId: string;
    berthPriority: number | null;
    operationId: string;
    operationName: string;
    eta: string | null;
    etaihs: string | null;
    atb: string | null;
    atbihs: string | null;
    atd: string | null;
    atdihs: string | null;
    ata: string | null;
    ataihs: string | null;
    etb: string | null;
    ecc: string | null;
    acc: string | null;
    etc: string | null;
    atc: string | null;
    etd: string | null;
    notes: string;
    updatedOn: string;
    modifiedBy: string;
    vesselStatusIHS: string | null;
    cargos: PortCallCargo[];
    dwt: number | null;
    nextDestinationNote: string | null;
    ihsVesselLocationId: string | null;
}

export class PortCallCargo {
    id: string | null= null;
    cargoType: string| null= null;
    cargoTypeId: string| null= null;
    cargoDescription: string| null= null;
    cargoRate: number | null = null;
    cargoRateUomId: string| null= null;
    cargoRateUom: string| null= null;
    cargoQuantity: number | null = null;
    cargoQuantityUom: string| null= null;
    cargoQuantityUomId: string| null= null;
}
