import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { FileDownloadService } from '@port-line-up/shared/util';
import { Asset } from '../../models/assets';

@Component({
    selector: 'plu-assets-list-download',
    templateUrl: 'assets-download.component.html',
    imports: [CommonModule, ButtonModule],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssetsDownloadComponent {

    assets: Asset[] = [];

    constructor(public config: DynamicDialogConfig, private readonly fileDownloadService: FileDownloadService) {
        this.assets = this.config.data.assets;
    }

    download(asset: Asset): void {
        this.fileDownloadService.download(asset.url, asset.fileName)
    }
}
