import { Inject, Injectable } from '@angular/core';
import { Column } from '../../models';
import { GridColumnFactory } from './grid-column-factory';
import { GRID_COLUMN_STORAGE_KEY } from './storage-key.token';

@Injectable()
export class GridColumnLocalStorageService {
    columns: Column[] | null = null;

    constructor(private readonly gridColumnsFactory: GridColumnFactory, @Inject(GRID_COLUMN_STORAGE_KEY)
    private readonly key: string) {}

    write(columns: Column[]): void {
        if(this.key) {
            localStorage.setItem(this.key, JSON.stringify(columns));
        }
        this.columns = columns;
    }

    read(): Column[] {
        if(this.columns) {
            return this.columns;
        }

        let columns: string | null = '';

        if(this.key) {
            columns = localStorage.getItem(this.key);
        }


        if (!columns || !this.checkIfLocalStorageColumnsAreValid(JSON.parse(columns) as Column[], this.gridColumnsFactory.create())) {
            this.columns = this.gridColumnsFactory.create();
            this.write(this.columns);
            return this.columns ?? [];
        }
       this.columns = JSON.parse(columns);
       return this.columns ?? [];
    }

    private checkIfLocalStorageColumnsAreValid(columnsFromLocalStorage: Column[], columnFromFactory: Column[]): boolean {

        if (!columnsFromLocalStorage  || columnsFromLocalStorage.length  === 0) {
            return false;
        }

        for (const columnFromLocalStorage of columnsFromLocalStorage) {
            if(!columnFromFactory.find((factoryColumn) => factoryColumn.name === columnFromLocalStorage.name)) {
                return false;
            }
        }

        return true;
    }
}
