import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum Theme {
    Dark = 'dark',
    Light = 'light',
}

const localStorageThemeKey = 'theme';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    private readonly currentThemeSubject = new BehaviorSubject<string>('');
    currentTheme$ = this.currentThemeSubject.asObservable();
    currentTheme!: string;

    constructor(@Inject(DOCUMENT)
    private readonly document: Document) {
        this.currentTheme$.subscribe((x) => (this.currentTheme = x));
        this.init();
    }

    setDarkTheme(): void {
        this.loadStyle(Theme.Dark);
    }

    setLightTheme(): void {
        this.loadStyle(Theme.Light);
    }

    private init(): void {
        if (localStorage) {
            const theme = localStorage.getItem(localStorageThemeKey) as Theme;
            if (theme && Object.values(Theme).includes(theme)) {
                this.loadStyle(theme);
                return;
            }
        }

        this.loadStyle(Theme.Light);
    }

    private loadStyle(styleName: Theme): void {
        const head = this.document.getElementsByTagName('head')[0];

        const themeLink = this.document.querySelector('link[href$="-theme.css"]') as HTMLLinkElement;

        if (themeLink) {
            themeLink.href = `${styleName}-theme.css`;
        } else {
            const style = this.document.createElement('link');
            style.rel = 'stylesheet';
            style.href = `${styleName}-theme.css`;

            head.appendChild(style);
        }

        if (this.document.documentElement.classList?.contains(this.currentTheme)) {
            this.document.documentElement.classList.remove(this.currentTheme);
        }
        this.currentThemeSubject.next(styleName);
        this.document.documentElement.classList.add(styleName);

        if (localStorage) {
            localStorage.setItem(localStorageThemeKey, styleName);
        }
    }
}
