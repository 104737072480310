import { ChangeDetectionStrategy, EventEmitter, Input, Output, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BreadcrumbItem } from './breadcrumb-item';

@Component({
    selector: 'plu-breadcrumb',
    templateUrl: 'breadcrumb.component.ts.html',
    styleUrls: ['breadcrumb.component.scss'],
    imports: [CommonModule, RouterModule],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent {
    @Input() set item(item: BreadcrumbItem | null) {
        this._item = item;
    }
    get item() {
        return this._item;
    }

    @Output() itemClicked: EventEmitter<BreadcrumbItem> = new EventEmitter();

    private _item: BreadcrumbItem | null = null;

    onItemClick(item: BreadcrumbItem): void {
        this.itemClicked.emit(item);
        if (item.routerLink || item.url) {
            this.item = item;
        }
    }
}
