import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PortCallPresentation } from '../models';
import { ColumnFilterItem, PartyLookup, LookupItem, sortColumnFilterItemsAndSetEmptyAsFirst } from '@port-line-up/shared/util';

@Injectable()
export class ColumnFilterService {
    private readonly filterableStatusesSubject = new BehaviorSubject<ColumnFilterItem<LookupItem, string>[]>([]);
    filterableStatuses$ = this.filterableStatusesSubject.asObservable();

    private readonly filterableTerminalsSubject = new BehaviorSubject<ColumnFilterItem<LookupItem, string>[]>([]);
    filterableTerminals$ = this.filterableTerminalsSubject.asObservable();

    private readonly filterableBerthsSubject = new BehaviorSubject<ColumnFilterItem<LookupItem, string>[]>([]);
    filterableBerths$ = this.filterableBerthsSubject.asObservable();

    private readonly filterableCargosSubject = new BehaviorSubject<ColumnFilterItem<LookupItem, LookupItem>[]>([]);
    filterableCargos$ = this.filterableCargosSubject.asObservable();

    private readonly filterableOperationsSubject = new BehaviorSubject<ColumnFilterItem<LookupItem, string>[]>([]);
    filterableOperations$ = this.filterableOperationsSubject.asObservable();

    private readonly filterableCharterersSubject = new BehaviorSubject<ColumnFilterItem<PartyLookup, string>[]>([]);
    filterableCharterers$ = this.filterableCharterersSubject.asObservable();

    private readonly filterableShippingAgentsSubject = new BehaviorSubject<ColumnFilterItem<PartyLookup, string>[]>([]);
    filterableShippingAgents$ = this.filterableShippingAgentsSubject.asObservable();

    private readonly filterableShippersSubject = new BehaviorSubject<ColumnFilterItem<PartyLookup, string>[]>([]);
    filterableShippers$ = this.filterableShippersSubject.asObservable();

    private readonly filterableVesselNamesSubject = new BehaviorSubject<ColumnFilterItem<LookupItem, string>[]>([]);
    filterableVesselNames$ = this.filterableVesselNamesSubject.asObservable();

    private readonly filterableVesselTypesSubject = new BehaviorSubject<ColumnFilterItem<string, string>[]>([]);
    filterableVesselTypes$ = this.filterableVesselTypesSubject.asObservable();

    private readonly filterableVoyageNumbersSubject = new BehaviorSubject<ColumnFilterItem<string, string>[]>([]);
    filterableVoyageNumbers$ = this.filterableVoyageNumbersSubject.asObservable();

    private readonly filterableNextDestinationsSubject = new BehaviorSubject<ColumnFilterItem<string, string>[]>([]);
    filterableNextDestinations$ = this.filterableNextDestinationsSubject.asObservable();

    init(portCalls: PortCallPresentation[]): void {
        this.updateAll(portCalls);
    }

    private updateAll(portCalls: PortCallPresentation[]): void {
        const terminals = new Map<string, ColumnFilterItem<LookupItem, string>>();
        const vesselStatuses = new Map<string, ColumnFilterItem<LookupItem, string>>();

        const berths = new Map<string, ColumnFilterItem<LookupItem, string>>();
        const cargos = new Map<string, ColumnFilterItem<LookupItem, LookupItem>>();
        const operations = new Map<string, ColumnFilterItem<LookupItem, string>>();

        const charterers = new Map<string, ColumnFilterItem<PartyLookup, string>>();
        const shippers = new Map<string, ColumnFilterItem<PartyLookup, string>>();
        const shippingAgents = new Map<string, ColumnFilterItem<PartyLookup, string>>();
        const vesselNames = new Map<string, ColumnFilterItem<LookupItem, string>>();
        const voyageNumbers = new Map<string, ColumnFilterItem<string, string>>();
        const nextDestinations: Map<string, ColumnFilterItem<string, string>> = new Map();
        const vesselTypes = new Map<string, ColumnFilterItem<string, string>>();

        const setLookupItem = (id: string | null, displayText: string | null, map: Map<string, ColumnFilterItem<LookupItem, string>>) => {
            if (id && displayText) {
                map.set(id, ColumnFilterItem.create(displayText, LookupItem.create(id, displayText), 'id'));
            } else {
                map.set('', ColumnFilterItem.createEmpty());
            }
        };

        const setParty = (code: string | null, displayText: string | null, map: Map<string, ColumnFilterItem<PartyLookup, string>>) => {
            if (code && displayText) {
                map.set(displayText, ColumnFilterItem.create(displayText, PartyLookup.create(code, displayText), 'displayText'));
            } else {
                map.set('', ColumnFilterItem.createEmpty());
            }
        };

        const setStrings = (item: string | null, map: Map<string, ColumnFilterItem<string, string>>) => {
            if (item) {
                map.set(item, ColumnFilterItem.create(item, item));
            } else {
                map.set('', ColumnFilterItem.createEmpty());
            }
        };

        portCalls.forEach((item: PortCallPresentation) => {
            setLookupItem(item.terminalId, item.terminalName, terminals);
            setLookupItem(item.berthId, item.berthName, berths);

            item.cargoType.forEach((type) => {
                if (type?.id) {
                    cargos.set(type.id, ColumnFilterItem.create(type.displayText, type, 'id', 'id'));
                } else {
                    cargos.set('', ColumnFilterItem.createEmpty());
                }
            });

            setLookupItem(item.vesselStatusId, item.vesselStatus, vesselStatuses);
            setLookupItem(item.operationId, item.operationName, operations);
            setParty(item.chartererCode, item.charterer, charterers);
            setParty(item.shipperCode, item.shipper, shippers);
            setParty(item.shippingAgentCode, item.shippingAgent, shippingAgents);

            if (item.vesselName) {
                vesselNames.set(item.vesselId, ColumnFilterItem.create(item.vesselName, LookupItem.create(item.vesselId, item.vesselName), 'displayText'));
            }

            setStrings(item.vesselType, vesselTypes);
            setStrings(item.voyageNumber, voyageNumbers);
            setStrings(item.nextDestinationNote, nextDestinations);
        });

        this.filterableTerminalsSubject.next(sortColumnFilterItemsAndSetEmptyAsFirst([...terminals.values()]));
        this.filterableBerthsSubject.next(sortColumnFilterItemsAndSetEmptyAsFirst([...berths.values()]));
        this.filterableCargosSubject.next(sortColumnFilterItemsAndSetEmptyAsFirst([...cargos.values()]));
        this.filterableStatusesSubject.next(sortColumnFilterItemsAndSetEmptyAsFirst([...vesselStatuses.values()]));
        this.filterableOperationsSubject.next(sortColumnFilterItemsAndSetEmptyAsFirst([...operations.values()]));
        this.filterableVesselTypesSubject.next(sortColumnFilterItemsAndSetEmptyAsFirst([...vesselTypes.values()]));

        this.filterableVesselNamesSubject.next(sortColumnFilterItemsAndSetEmptyAsFirst([...vesselNames.values()]));
        this.filterableVoyageNumbersSubject.next(sortColumnFilterItemsAndSetEmptyAsFirst([...voyageNumbers.values()]));
        this.filterableNextDestinationsSubject.next(sortColumnFilterItemsAndSetEmptyAsFirst([...nextDestinations.values()]));
        this.filterableCharterersSubject.next(sortColumnFilterItemsAndSetEmptyAsFirst([...charterers.values()]));
        this.filterableShippersSubject.next(sortColumnFilterItemsAndSetEmptyAsFirst([...shippers.values()]));
        this.filterableShippingAgentsSubject.next(sortColumnFilterItemsAndSetEmptyAsFirst([...shippingAgents.values()]));
    }
}
