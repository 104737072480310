import { Directive } from '@angular/core';
import { DomHandler } from 'primeng/dom';
import { ColumnFilter } from 'primeng/table';
import { FilterMetadata } from 'primeng/api';

/**
 * Customizes the behavior of a column filter by defining actions for removing a filter constraint and updating the match mode when filter metadata changes, then reapplying the filter and updating the UI focus accordingly.
 */
@Directive({ selector: '[pluApplyFilterOnFilterMetadataChange]', standalone: true })
export class ApplyFilterOnFilterMetadataChangeDirective {
    constructor(private readonly columnFilter: ColumnFilter) {
        this.columnFilter.removeConstraint = (filterMeta: FilterMetadata) => {
            this.columnFilter.dt.filters[<string>this.columnFilter.field] = (<FilterMetadata[]>this.columnFilter.dt.filters[<string>this.columnFilter.field]).filter((meta) => meta !== filterMeta);
            this.columnFilter.dt._filter();
            DomHandler.focus(this.columnFilter.clearButtonViewChild?.nativeElement);
        }

        this.columnFilter.onMenuMatchModeChange = (value: any, filterMeta: FilterMetadata) => {
            filterMeta.matchMode = value;
            this.columnFilter.dt._filter();
        }
    }
}
