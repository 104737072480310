import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ComponentStore } from '@ngrx/component-store';
import { PortCallPresentation } from '../../models';
import { SelectedVessel } from '../../components';
import { LookupItem } from '@port-line-up/shared/util';

export interface TableFilteredState {
    portCalls: PortCallPresentation[];
    isMapOpened: boolean;
    selectedVessel: SelectedVessel | null;
    isTrafficChartOpened: boolean;
    isReportOpened: boolean;
    availableCargos: Map<string, LookupItem>,
    selectedCargo: LookupItem | null,
    isCargoChartOpened: boolean;
}

const initialState: TableFilteredState = {
    portCalls: [],
    selectedVessel: null,
    isTrafficChartOpened: false,
    isMapOpened: false,
    isReportOpened: false,
    availableCargos: new Map<string, LookupItem>(),
    selectedCargo: null,
    isCargoChartOpened: false,
};

@Injectable()
export class TableFilteredStore extends ComponentStore<TableFilteredState> {
    constructor() {
        super(initialState);
    }

    readonly isTrafficChartOpened$: Observable<boolean> = this.select((state) => state.isTrafficChartOpened);
    readonly selectedVessel$: Observable<SelectedVessel | null> = this.select((state) => state.selectedVessel);
    readonly isMapOpened$: Observable<boolean> = this.select((state) => state.isMapOpened);
    readonly isReportOpened$: Observable<boolean> = this.select((state) => state.isReportOpened);
    readonly portCalls$: Observable<PortCallPresentation[]> = this.select((state) => state.portCalls);
    readonly selectedCargo$: Observable<LookupItem | null> = this.select((state) => state.selectedCargo);
    readonly availableCargos$: Observable<LookupItem[]> = this.select((state) => Array.from(state.availableCargos.values()));
    readonly isCargoChartOpened$: Observable<boolean> = this.select((state) => state.isCargoChartOpened);

    toggleMap = this.updater((state) => ({
        ...state,
        isMapOpened: !state.isMapOpened,
        isTrafficChartOpened: false,
        isReportOpened: false,
        isCargoChartOpened: false,
        selectedVessel: state.isMapOpened ? null : ({ ...state.selectedVessel } as SelectedVessel),
    }));

    toggleCargoChart = this.updater((state) => ({
        ...state,
        isCargoChartOpened: !state.isCargoChartOpened,
        isMapOpened: false,
        isTrafficChartOpened: false,
        isReportOpened: false,
        selectedVessel: null,
        selectedCargo: state.isCargoChartOpened ? null : state.selectedCargo,
    }));

    toggleReport = this.updater((state) => ({
        ...state,
        isReportOpened: !state.isReportOpened,
        isMapOpened: false,
        isTrafficChartOpened: false,
        isCargoChartOpened: false,
        selectedVessel: null,
        selectedCargo: null
    }));

    selectVessel = this.updater((state, selectedVessel: SelectedVessel | null) => ({
        ...state,
        selectedVessel: selectedVessel
            ? {
                ...selectedVessel,
                isSelected: selectedVessel.portCallId === state.selectedVessel?.portCallId,
            }
            : null,
        isMapOpened: true,
        isTrafficChartOpened: false,
        isCargoChartOpened: false,
    }));

    toggleChart = this.updater((state) => ({
        ...state,
        isTrafficChartOpened: !state.isTrafficChartOpened,
        isMapOpened: false,
        isReportOpened: false,
        selectedCargo: null,
        selectedVessel: null,
        isCargoChartOpened: false,
    }));

    closeUtils = this.updater((state) => ({
        ...state,
        isTrafficChartOpened: false,
        isMapOpened: false,
        isCargoChartOpened: false,
        selectedVessel: null,
        selectedCargo: null,
        isReportOpened: false,
    }));

    loadPortCalls = this.updater((state, portCalls: PortCallPresentation[]) => ({
        ...state,
        portCalls: portCalls,
        availableCargos: this.getAvailableCargos(portCalls)
    }));

    selectCargo = this.updater((state, cargo: LookupItem | null) => ({
        ...state,
        selectedCargo: cargo,
    }));

    private getAvailableCargos(portCalls: PortCallPresentation[]): Map<string, LookupItem> {
        const map = new Map<string, LookupItem>();
        portCalls.forEach((portCall) => {
            if(portCall.cargoType) {
                portCall.cargoType.forEach(cargoType => {
                    if(cargoType) {
                        map.set(cargoType.id, LookupItem.create(cargoType.id, cargoType.displayText));
                    }
                })
            }
        });
        return map;
    }
}
