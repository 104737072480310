import { AfterViewInit, DestroyRef, Directive, HostBinding, inject, OnDestroy } from '@angular/core';
import { distinctUntilChanged, filter, fromEvent } from 'rxjs';
import { Table } from 'primeng/table';
import { SliderService } from '../../services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
    selector: '[pluTableGlobalHorizontalScroll]',
    standalone: true,
})
export class TableGlobalHorizontalScrollDirective implements AfterViewInit, OnDestroy {
    private scrollLeft = 0;
    private tableObserver!: ResizeObserver;
    private readonly destroyRef = inject(DestroyRef);
    
    @HostBinding('class') className = 'hide-table-horizontal-scrollbar';
    
    constructor(private readonly table: Table, private readonly slideService: SliderService) {}

    ngAfterViewInit(): void {
       this.listenToTableScrollEvent();
       this.subscribeToTableScrollLeftValueChange();
       this.observeTableResize();
    }

    ngOnDestroy(): void {
        this.tableObserver.disconnect();
    }

    private observeTableResize(): void {
        this.tableObserver = new ResizeObserver(entries => {
            entries.forEach(entry => {
                this.slideService.setTableWidthTo(entry.contentRect.width)
            });
        });

        this.tableObserver.observe(this.table.tableViewChild?.nativeElement);
    }

    private subscribeToTableScrollLeftValueChange(): void {
        this.slideService.tableScrollLeft$.pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged()).subscribe((scrollLeft) => {
            if (this.table.wrapperViewChild && typeof scrollLeft === "number" && this.scrollLeft !== scrollLeft) {
                this.scrollLeft = scrollLeft;
                this.table.wrapperViewChild.nativeElement.scrollLeft = scrollLeft;
            }
        });
    }

    private listenToTableScrollEvent(): void {
        fromEvent(this.table.wrapperViewChild?.nativeElement, 'scroll')
            .pipe(takeUntilDestroyed(this.destroyRef), filter(wrapper => {
                const { scrollLeft } = (wrapper as any).target;
                if (scrollLeft === this.scrollLeft) {
                    return false;
                }
                this.scrollLeft = scrollLeft;
                return true;
            })).subscribe(( wrapper ) => {
            const { scrollLeft } = (wrapper as any).target;
            this.slideService.tableScrollLeft(scrollLeft);
        });
    }
}
