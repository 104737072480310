import { DestroyRef, Directive, inject, Input, OnInit } from '@angular/core';
import { Table } from 'primeng/table';
import { FilterMetadata } from 'primeng/api';
import { GridFilter } from '../../models';
import { concatMap, Observable, of, switchMap, tap } from 'rxjs';
import { GridFilterItem } from '../../models/grid-filter';
import { GridFilterFactory } from './grid-filter-factory';
import { GridFilterReactiveService } from './grid-filter-reactive.service';
import { PresentationTableFilterIds } from '../../models/presentation-table-filter-ids';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({ selector: '[pluGridFilter]', standalone: true })
export class GridFilterDirective implements OnInit {
    @Input('pluGridFilter') set key(key: string | null) {
        this._key = key;
        this.initialize().subscribe();
    };
    get key() {
        return this._key;
    }
    @Input() initialFilters: GridFilterItem | null = null;

    private readonly destroyRef = inject(DestroyRef);
    private currentAppliedFilter!: GridFilterItem;
    private _key: string | null = null;
    constructor(private readonly table: Table, private readonly gridFilterReactiveService: GridFilterReactiveService, private readonly gridFiltersFactory: GridFilterFactory) {}

    ngOnInit(): void {
        this.subscribeToFilterChange();
        this.subscribeToTableOnFilter();
    }
   
    private initialize(): Observable<void> {
        if(!this.key) {
            return of();
        }

        if(this.initialFilters) {
            return this.writeInitialFilters(this.initialFilters);
        } else {
            return this.gridFilterReactiveService.readBy(this.key).pipe(
                concatMap(filters => this.writeInitialFilters(filters))
            )
        }
    }

    private writeInitialFilters(filters: GridFilterItem | undefined): Observable<void> {
        this.convertDateFiltersToDate(filters);
        this.table.filters = filters ?? {};

        if (this.initialFilters ) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return this.gridFilterReactiveService.write(new GridFilter(this.key!, filters ?? {}));
        }

        return of();
    }

    private convertDateFiltersToDate(portCallFilters: GridFilterItem | undefined): void {
        const dateFiltersNames = [PresentationTableFilterIds.ata, PresentationTableFilterIds.atb, PresentationTableFilterIds.acc, PresentationTableFilterIds.atd, PresentationTableFilterIds.atc];
        dateFiltersNames.forEach((dateFilterName: string) => {
            if (portCallFilters && portCallFilters[dateFilterName] as FilterMetadata[]) {
                (portCallFilters[dateFilterName] as FilterMetadata[]).forEach((portCallFilter) => {
                    portCallFilter.value = this.convertStringToDate(portCallFilter.value);
                })
            }
        });
    }

    private convertStringToDate(date: string | null): Date | string | null {
        return date ? new Date(date) : date;
    }

    private subscribeToFilterChange(): void {
        this.gridFilterReactiveService.portCallFilters$
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                tap((filters) => {
                    if (filters && this.key === filters.key && !this.compareFilters(this.currentAppliedFilter, filters.filters)) {
                            this.currentAppliedFilter = JSON.parse(JSON.stringify(filters.filters));
                            this.table.filters = filters.filters ?? {};
                            this.table._filter();
                    }
                })
            )
            .subscribe();
    }

    private subscribeToTableOnFilter(): void {
        this.table.onFilter.pipe(takeUntilDestroyed(this.destroyRef)).pipe(switchMap(filters => {
            const portCallFilter = new GridFilter(this.key as string, this.gridFiltersFactory.create());
            portCallFilter.filters = filters.filters as any;
            return this.gridFilterReactiveService.write(portCallFilter);
        })).subscribe();
    }

    private compareFilters(currentAppliedFilter: GridFilterItem, receivedFilters: GridFilterItem | undefined): boolean {
        return JSON.stringify(currentAppliedFilter) === JSON.stringify(receivedFilters)
    }
}
