<div class="w-full h-full" *ngIf="partyFilterChip() || filterChips().length > 0">
    <p-chip *ngFor="let filterChip of filterChips()" [removable]="true" styleClass="px-2 py-1 mr-2 mt-2" (onRemove)="onClearFilter(filterChip.filterId)">
        <span class="flex items-center cursor-default">
            <p class="inline-block font-medium text-xs">{{ filterChip.title }}: </p>&nbsp;
            <p class="inline-block text-xs max-w-48 truncate hover:text-clip hover:max-w-none">{{ filterChip.values[0] }}</p>
         </span>
         <span class="ml-2 bg-blue-700  rounded-full px-1.5 flex align-items-center justify-content-center cursor-pointer" *ngIf="filterChip.values.length > 1">
            <p class="inline-block text-white" [pTooltip]="generateTooltip(filterChip.title, filterChip.values)">+{{filterChip.values.length - 1}}</p>
        </span>
    </p-chip>
    <p-chip *ngIf="partyFilterChip()" [removable]="true" styleClass="px-2 py-1 mr-2 mt-2" (onRemove)="onClearFilter(partyFilterChip()?.filterId ?? '')">
        <span class="flex items-center cursor-default">
            <p class="inline-block font-medium text-xs">{{ partyFilterChip()?.partyChipItems?.[0]?.title }}: </p>&nbsp;
            <p class="inline-block text-xs max-w-48 truncate hover:text-clip hover:max-w-none">{{ partyFilterChip()?.partyChipItems?.[0]?.value }}</p>
         </span>
        <span class="ml-2 bg-blue-700  rounded-full px-1.5 flex align-items-center justify-content-center cursor-pointer" *ngIf="(partyFilterChip()?.partyChipItems?.length ?? 0) > 1">
            <p class="inline-block text-white" [pTooltip]="generatePartyTooltip(partyFilterChip()?.partyChipItems ?? [])">+{{(partyFilterChip()?.partyChipItems?.length ?? 0) - 1}}</p>
        </span>
    </p-chip>
    <p-chip label="Clear All" styleClass="cursor-pointer bg-gray-600 text-white mt-2" (click)="clearAllFilters.emit($event)" (keydown.enter)="clearAllFilters.emit($event)"></p-chip>
</div>
