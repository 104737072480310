import { AfterViewInit, ChangeDetectionStrategy, Component, DestroyRef, inject, Input, OnDestroy } from '@angular/core';
import { Coordinates } from '@port-line-up/shared/data-access';
import * as mapboxgl from 'mapbox-gl';
import { MapLayerMouseEvent } from 'mapbox-gl';
import { MessageService } from 'primeng/api';
import { debounceTime, first } from 'rxjs';
import { MapImage } from './models/map-image';
import { VesselLocation } from './models/vessel-location';
import { SelectedVessel } from '.';
import { VesselLocationFactoryService } from './services/vessel-location.factory';
import { VesselTrackingMapOptionsService } from './services/vessel-tracking-map-options.service';
import { TableFilteredService } from '../../services';
import { PortCallPresentation } from '../../models';
import { PluDatePipe } from '@port-line-up/shared/ui/pipes';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'plu-map',
    templateUrl: 'map.component.html',
    providers: [VesselLocationFactoryService, VesselTrackingMapOptionsService, PluDatePipe],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent implements AfterViewInit, OnDestroy {
    @Input() mapContainerId = 'mapContainerId';
    @Input() initialCoordinates: Coordinates | null = null;
    private vesselLocations: VesselLocation[] = [];
    private readonly portCalls$ = this.tableFilteredService.portCalls$;
    private map: mapboxgl.Map | null = null;
    private hoveredVessel: mapboxgl.MapboxGeoJSONFeature | null = null;
    private readonly hoverVesselTooltip = new mapboxgl.Popup(this.optionsService.getVesselTooltipOptions());
    private portCalls: PortCallPresentation[] = [];

    private readonly destroyRef = inject(DestroyRef);

    constructor(
        private readonly optionsService: VesselTrackingMapOptionsService,
        private readonly vesselLocationFactory: VesselLocationFactoryService,
        private readonly tableFilteredService: TableFilteredService,
        private readonly messageService: MessageService
    ) {}

    ngAfterViewInit(): void {
        this.portCalls$.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(1000)).subscribe((portCalls) => {
            this.portCalls = portCalls;
            this.vesselLocations = this.vesselLocationFactory.create(this.portCalls);
            const coordinates = this.getInitialCoordinates();
            if (!this.map) {
                this.map = new mapboxgl.Map(this.optionsService.createMapboxOptions(coordinates.longitude, coordinates.latitude, this.mapContainerId));
                this.loadMarks();
                this.addMarks(this.vesselLocations);
            } else {
                this.updateMapSource();
                this.mapFlyTo(coordinates.longitude, coordinates.latitude, 11);
                this.tableFilteredService.selectedVessel$.pipe(first()).subscribe((result) => {
                    if (result) this.handleVesselSelectionChange(result);
                });
            }
        });

        this.addHoverEvents();
        this.addClickEvent();
    }

    ngOnDestroy(): void {
        this.map?.remove();
    }

    private loadMarks(): void {
        const imagesToLoad: MapImage[] = [
            new MapImage(`/assets/images/map_pin_vessel_16.png`, 'blue-0'),
            new MapImage(`/assets/images/map_pin_vessel_red_16.png`, 'red-0'),
            new MapImage(`/assets/images/map_pin_vessel_red_alt_16.png`, 'red-alt-0'),
            new MapImage(`/assets/images/map_pin_vessel_gray_16.png`, 'gray-0'),
            new MapImage(`/assets/images/circle.png`, 'circle-sdf', true),
        ];

        imagesToLoad.forEach((image) => {
            this.map?.loadImage(image.url, (error, imageEl) => {
                if (error) {
                    throw new Error('Failed to load Marks!');
                }
                this.map?.addImage(image.name, imageEl as HTMLImageElement, { sdf: image.sdf });
            });
        });
    }

    private addMarks(vesselLocation: VesselLocation[]): void {
        this.map?.on('load', () => {
            this.map?.addSource(this.optionsService.vesselsSourceName, this.optionsService.getVesselsSource(vesselLocation));
            this.map?.addLayer(this.optionsService.getVesselsLayer());
            this.map?.addSource(this.optionsService.focusedVesselSourceName, this.optionsService.getFocusedVesselSource(null));
            this.optionsService.getFocusedVesselLayers().forEach((l) => {
                this.map?.addLayer(l);
            });
            this.subscribeToVesselSelectionChange();
        });
    }

    private addHoverEvents(): void {
        const mouseMoveHandler = (event: MapLayerMouseEvent) => {
            if (event.originalEvent?.defaultPrevented) {
                return;
            }
            event.originalEvent?.preventDefault();

            if (event.features && event.features[0] && this.map) {
                this.hoveredVessel = event.features[0];
                this.map.getCanvas().style.cursor = 'pointer';
                this.showHoverPopup(event);
            }
        };

        const mouseOutHandler = (event: MapLayerMouseEvent) => {
            event.originalEvent?.preventDefault();
            if (this.hoveredVessel && this.map) {
                this.map.getCanvas().style.cursor = '';
                this.hoveredVessel = null;
                this.hideHoverPopup();
            }
        };

        this.map?.on('mousemove', this.optionsService.vesselsLayerName, mouseMoveHandler);
        this.map?.on('mouseout', this.optionsService.vesselsLayerName, mouseOutHandler);
    }

    private addClickEvent(): void {
        const mouseClickHandler = (event: MapLayerMouseEvent) => {
            event.originalEvent?.preventDefault();
            if (this.hoveredVessel && this.map && this.hoveredVessel.properties && 'coordinates' in this.hoveredVessel.geometry) {
                const clickedPortCallId = this.hoveredVessel.properties['portCallId'];
                const clickedPortCallImoNumber = this.hoveredVessel.properties['imo'];
                this.tableFilteredService.selectVessel({ portCallId: clickedPortCallId, imoNumber: clickedPortCallImoNumber });
            }
        };

        this.map?.on('click', this.optionsService.vesselsLayerName, mouseClickHandler);
    }

    private flyTo(longitude: number, latitude: number, portCallId: string): void {
        const vesselLocation = this.vesselLocations.find((x) => x.portCallId === portCallId);
        if (vesselLocation) {
            const source = this.map?.getSource(this.optionsService.focusedVesselSourceName) as mapboxgl.GeoJSONSource;
            if(source){
                source.setData(this.optionsService.getFocusedVesselSource(vesselLocation).data as GeoJSON.Feature<GeoJSON.Geometry>);
            }
        }

        this.mapFlyTo(longitude, latitude, 14);
    }

    private mapFlyTo(longitude: number, latitude: number, zoom: number) {
        this.map?.flyTo({
            center: new mapboxgl.LngLat(longitude, this.normalizeLat(latitude)),
            curve: 1,
            maxDuration: 6000,
            screenSpeed: 1.5,
            zoom: zoom,
        });
    }

    private flyOut(): void {
        (this.map?.getSource(this.optionsService.focusedVesselSourceName) as mapboxgl.GeoJSONSource).setData(
            this.optionsService.getFocusedVesselSource(null).data as GeoJSON.Feature<GeoJSON.Geometry>
        );
        this.map?.zoomOut();
    }

    private normalizeLat(lat: number): number {
        if (lat > 90) {
            return this.normalizeLat(lat - 180);
        }
        if (lat < -90) {
            return this.normalizeLat(lat + 180);
        }
        return lat;
    }

    private showHoverPopup(event: MapLayerMouseEvent) {
        if (event.features && 'coordinates' in event.features[0].geometry) {
            const coordinates = event.features[0].geometry.coordinates?.slice();
            const description = this.optionsService.getVesselTooltipHtml(event.features[0], coordinates as number[]);

            let cordX = +coordinates[0];
            const cordY = +coordinates[1];
            const longitude = event.lngLat.lng;

            while (Math.abs(longitude - cordX) > 180) {
                const result = cordX + longitude > cordX ? 360 : -360;
                cordX = result;
            }

            if (this.map instanceof mapboxgl.Map) {
                this.hoverVesselTooltip.setLngLat([cordX, cordY]).setHTML(description).addTo(this.map);
            }
        }
    }

    private hideHoverPopup(): void {
        this.hoverVesselTooltip.remove();
    }

    private getInitialCoordinates(): Coordinates {
        if (this.initialCoordinates) {
            return { latitude: this.initialCoordinates.latitude, longitude: this.initialCoordinates.longitude };
        }

        const minPriority = Math.min(...this.portCalls.map((item) => item.priority));
        const portCall = this.portCalls.find((x) => x.priority === minPriority);

        if (portCall?.longitude && portCall?.latitude) {
            return { latitude: +portCall?.latitude, longitude: +portCall?.longitude };
        }

        throw new Error('Failed to find port coordinates!');
    }

    private subscribeToVesselSelectionChange(): void {
        this.tableFilteredService.selectedVessel$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((result) => {
            if (result) this.handleVesselSelectionChange(result);
        });
    }

    private handleVesselSelectionChange(result: SelectedVessel): void {
        const portCall = this.portCalls.find((x) => x.id === result.portCallId);

        if (portCall && result.isSelected) {
            this.handleUnselect();
        } else if (portCall) {
            this.handleSelect(portCall, result.portCallId);
        }
    }

    private handleUnselect(): void {
        this.flyOut();
        this.tableFilteredService.selectVessel(null);
    }

    private handleSelect(portCall: PortCallPresentation, portCallId: string): void {
        if (portCall.longitude !== null && portCall.longitude !== undefined && portCall.latitude !== null && portCall.latitude !== undefined) {
            this.flyTo(+portCall.longitude, +portCall.latitude, portCallId);
        } else {
            this.messageService.add({ severity: 'info', detail: 'Vessel not found' });
        }
    }

    private updateMapSource() {
        const sourceId = this.optionsService.vesselsSourceName;
        const sourceDataEventName = 'sourcedata';

        const onLoad = (event: mapboxgl.MapSourceDataEvent & mapboxgl.EventData) => {
            if (event.sourceId === sourceId && this.map?.isSourceLoaded(sourceId)) {
                const source = this.map?.getSource(sourceId) as any;
                source.setData(this.optionsService.getVesselsSource(this.vesselLocations)?.data);
                this.map?.off(sourceDataEventName, onLoad);
            }
        };

        // remove previous listener
        this.map?.off(sourceDataEventName, onLoad);

        // add new listener
        this.map?.on(sourceDataEventName, onLoad);
    }
}
