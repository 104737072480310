import { Injectable } from '@angular/core';
import { LocalStorageKeys, StorageService } from '@port-line-up/shared/util';
import { Observable, map, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PortStorage {
    private readonly selectedPortsKey = LocalStorageKeys.SelectedPortsKey;
    private readonly selectedPortKey = LocalStorageKeys.SelectedPortKey;

    constructor(private readonly storage: StorageService) {}

    getUnloCodes(): Observable<string[]> {
        return this.storage.getItem(this.selectedPortsKey).pipe(map((codes) => JSON.parse(codes ?? '[]') as string[]));
    }

    saveUnloCodes(unloCodes: string[]): Observable<string[]> {
        return this.storage.setItem(this.selectedPortsKey, JSON.stringify(unloCodes)).pipe(map(() => unloCodes));
    }

    getSelectedUnloCode(): Observable<string> {
        return this.storage.getItem(this.selectedPortKey).pipe(map((code) => JSON.parse(code ?? '""') as string));
    }

    saveSelectedUnloCode(unloCode: string | null): Observable<string | null> {
        if(!unloCode) {
            return of(null);
        }
        return this.storage.setItem(this.selectedPortKey, JSON.stringify(unloCode)).pipe(map(() => unloCode));
    }
}
