import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { distinctUntilChanged } from 'rxjs';
import { SliderService } from '@port-line-up/shared/util';
import { HorizontalScrollDirective } from './horizontal-scroll.directive';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'plu-table-horizontal-scroll-bar',
    templateUrl: 'table-horizontal-scroll-bar.component.html',
    styleUrls: ['table-horizontal-scroll-bar.component.scss'],
    standalone: true,
    imports: [HorizontalScrollDirective],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableHorizontalScrollBarComponent implements OnInit {
    tableWidth = 0;
    private readonly destroyRef = inject(DestroyRef);

    constructor(private readonly sliderService: SliderService, private readonly cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.subscribeToWidthChange();
    }

    private subscribeToWidthChange(): void {
        this.sliderService.tableWidth$.pipe(takeUntilDestroyed(this.destroyRef),distinctUntilChanged()).subscribe((tableWidth) => {
            if (tableWidth && tableWidth > 0) {
                this.tableWidth = tableWidth;
                this.cdr.detectChanges();
            }
        })
    }
}
