import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { Observable, concatMap } from 'rxjs';
import { PortService } from '../http-services';
import { Port } from '../models';
import { BaseState, BaseStore, baseInitialState } from '../services';
import { PortStorage } from '../storage';

export interface PortState extends BaseState {
    selectedUnloCode: string | null;
    unloCodes: string[];
    ports: Port[];
}

const initialState: PortState = {
    selectedUnloCode: null,
    unloCodes: [],
    ports: [],
    ...baseInitialState,
};

@Injectable({ providedIn: 'root' })
export class PortStore extends BaseStore<PortState> {
    constructor(private readonly portService: PortService, private readonly portStorage: PortStorage) {
        super(initialState);
    }

    readonly unloCodes$: Observable<string[]> = this.select((state) => state.unloCodes);
    readonly ports$: Observable<Port[]> = this.select((state) => state.ports);
    readonly selectedUnloCode$: Observable<string | null> = this.select((state) => state.selectedUnloCode);
    readonly selectedPort$: Observable<Port> = this.select((state) => state.ports.find((p) => p.unloCode === state.selectedUnloCode) ?? new Port());

    loadUnloCodes = this.updater((state, unloCodes: string[]) => ({
        ...state,
        unloCodes: unloCodes,
        selectedUnloCode: unloCodes?.[0] ?? null,
    }));

    loadPorts = this.updater((state, ports: Port[]) => ({
        ...state,
        ports,
    }));

    loadSelectedUnloCode = this.updater((state, unloCode: string | null) => ({
        ...state,
        selectedUnloCode: unloCode ?? state.unloCodes?.[0],
    }));

    getPorts = this.effect((unloCodes$: Observable<string[]>) =>
        unloCodes$.pipe(
            concatMap((unloCodes) => {
                return this.portService.getPortsBy(unloCodes).pipe(
                    tapResponse(
                        (ports) => {
                            this.loadPorts(ports);
                        },
                        (error: HttpErrorResponse) => {
                            this.failure(error);
                        }
                    )
                );
            })
        )
    );

    saveUnloCodes = this.effect((unloCodes$: Observable<string[]>) =>
        unloCodes$.pipe(
            concatMap((unloCodes) => {
                return this.portStorage.saveUnloCodes(unloCodes).pipe(
                    tapResponse(
                        (unloCodes) => {
                            this.loadUnloCodes(unloCodes);
                        },
                        (error: Error) => {
                            throw Error(error.message);
                        }
                    )
                );
            })
        )
    );

    saveSelectedUnloCode = this.effect((saveSelectedUnloCode$: Observable<string | null>) =>
        saveSelectedUnloCode$.pipe(
            concatMap((unloCode) => {
                return this.portStorage.saveSelectedUnloCode(unloCode).pipe(
                    tapResponse(
                        (unloCode) => {
                            this.loadSelectedUnloCode(unloCode);
                        },
                        (error: Error) => {
                            throw Error(error.message);
                        }
                    )
                );
            })
        )
    );
}
