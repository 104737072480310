import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { PublicHoliday, ScheduledMaintenance } from '@port-line-up/shared/data-access';

@Injectable()
export class PortDetailMessageService {

    constructor(private readonly datePipe: DatePipe) {
    }

    createPublicHolidayMessage(publicHolidays: PublicHoliday[]): string {
        const stringBuilder: string[] = [];
        publicHolidays.forEach((publicHoliday) => {
            stringBuilder.push(`${publicHoliday.name}: ${this.datePipe.transform(new Date(publicHoliday.from))} - ${this.datePipe.transform(new Date(publicHoliday.to))} ${publicHoliday.note}`)
        });
        return stringBuilder.join('\n');
    }

    createScheduledMaintenanceMessage(scheduledMaintenances: ScheduledMaintenance[]): string {
        const stringBuilder: string[] = [];
        scheduledMaintenances.forEach((scheduledMaintenance) => {
            stringBuilder.push(`${scheduledMaintenance.terminalName}: ${this.datePipe.transform(new Date(scheduledMaintenance.from))} - ${this.datePipe.transform(new Date(scheduledMaintenance.to))} ${scheduledMaintenance.note}`)
        });
        return stringBuilder.join('\n');
    }
}
