import { ChangeDetectionStrategy, Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ButtonModule } from "primeng/button";
import { DragDropModule } from 'primeng/dragdrop';
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Column } from '@port-line-up/shared/util';

export enum DraggedColumnFrom {
    AvailableContainer ,
    VisibleContainer
}

@Component({
    selector: 'plu-grid-column-selector',
    templateUrl: 'grid-column-selector.component.html',
    styleUrls: ['grid-column-selector.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        ButtonModule,
        DragDropModule
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridColumnSelectorComponent {

    availableColumns: Column[];
    visibleColumns: Column[] = [];
    columnsWithDisabledReorder: Column[] = [];

    DraggedColumnFrom = DraggedColumnFrom;


    private draggedColumn: Column | null = null;
    private draggedColumnFromContainer: DraggedColumnFrom | null = null;

    constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {

        this.availableColumns = this.config.data.availableColumns;
        this.columnsWithDisabledReorder = this.availableColumns.filter(x => x.disableColumnReorder);

        this.visibleColumns = this.config.data.visibleColumns.filter((x: Column) =>!this.columnsWithDisabledReorder.map(x => x.name).includes(x.name));

        this.availableColumns = this.availableColumns.filter((x: Column) => !this.visibleColumns.map(x => x.name).includes(x.name) && !this.columnsWithDisabledReorder.map(x => x.name).includes(x.name));
    }

    dragStart(column: Column, draggedColumnFrom: DraggedColumnFrom): void {
        this.draggedColumn = column;
        this.draggedColumnFromContainer = draggedColumnFrom;
    }

    dropToAvailableColumnsContainer(): void {
        if (!this.draggedColumn || this.availableColumns.map(x => x.name).includes(this.draggedColumn.name)) {
            return;
        }

        this.visibleColumns = this.visibleColumns.filter(v => v.name !== this.draggedColumn?.name);
        this.availableColumns = [...this.availableColumns, this.draggedColumn];
        this.dragEnd();
    }

    dropToVisibleColumnsContainer(dropIndex: number): void {
        if (!this.draggedColumn || this.draggedColumnFromContainer === null) {
            return;
        }

        if (this.draggedColumnFromContainer === this.DraggedColumnFrom.VisibleContainer) {
            this.visibleColumns = this.visibleColumns.filter(v => v.name !== this.draggedColumn?.name);
        } else {
            this.availableColumns = this.availableColumns.filter(v => v.name !== this.draggedColumn?.name);
        }

        this.visibleColumns = [...this.visibleColumns.slice(0,dropIndex), this.draggedColumn, ...this.visibleColumns.slice(dropIndex)]
        this.dragEnd();
    }

    dragEnd(): void {
        this.draggedColumn = null;
        this.draggedColumnFromContainer = null;
    }

    apply(): void {
        this.ref.close([...this.columnsWithDisabledReorder, ...this.visibleColumns]);
    }

    close(): void {
        this.ref.close();
    }
}
