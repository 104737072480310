import { AfterViewInit, DestroyRef, Directive, ElementRef, inject, Input, Renderer2 } from '@angular/core';
import { first } from 'rxjs';
import { PortCallPresentation } from '../../models';
import { PortCallDelayedService } from './port-call-delayed.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({ selector: '[pluPortCallDelayed]', standalone: true })
export class PortCallDelayedDirective implements AfterViewInit {
    @Input('pluPortCallDelayed') set portCall(portCall: PortCallPresentation) {
        this._portCall = portCall;
        this.portCallDelayedService.areDelayedVesselsMarked$.pipe(first()).subscribe((areDelayedVesselsMarked) => {
            this.markDelayedVessel(areDelayedVesselsMarked);
        });
    }

    private _portCall!: PortCallPresentation;
    private readonly destroyRef = inject(DestroyRef);

    constructor(private readonly portCallDelayedService: PortCallDelayedService, private readonly el: ElementRef, private readonly renderer: Renderer2) {}


    ngAfterViewInit(): void {
        this.portCallDelayedService.areDelayedVesselsMarked$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((areDelayedVesselsMarked) => {
            this.markDelayedVessel(areDelayedVesselsMarked);
        });
    }

    private markDelayedVessel(shouldMark: boolean): void {
        if (!shouldMark) {
            this.removeClass();
            return;
        }

        if (this.isVesselDelayed(this._portCall.ata, this._portCall.ataihs, this._portCall.eta, this._portCall.etaihs)) {
            this.setClass();
        } else {
            this.removeClass();
        }
    }

    isVesselDelayed(ata: Date | null, ataIhs: Date | null, eta: Date | null, etaIhs: Date | null): boolean {

        if (ata || ataIhs) {
            return false;
        }

        const now: number = new Date().getTime();

        if (eta) {
            return now > new Date(eta).getTime()
        }

        if (etaIhs) {
            return now > new Date(etaIhs).getTime()
        }

        return false;
    }

    private setClass(): void {
        this.renderer.addClass(this.el.nativeElement, 'datatable-row-delayed');
        this.renderer.addClass(this.el.nativeElement, 'ease-in-out');
        this.renderer.addClass(this.el.nativeElement, 'duration-300');
    }

    private removeClass(): void {
        this.renderer.removeClass(this.el.nativeElement, 'datatable-row-delayed');
        this.renderer.addClass(this.el.nativeElement, 'inherit');
    }
}
