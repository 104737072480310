import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';
import { CompanyUserRole } from '../enums';

@Injectable({ providedIn: 'root' })
export class CompanyUserRoleService {
    private readonly types: KeyValue<CompanyUserRole, string>[] = [
        { key: CompanyUserRole.LocalAdmin, value: 'Local Admin' },
        { key: CompanyUserRole.Editor, value: 'Editor' },
        { key: CompanyUserRole.Viewer, value: 'Viewer' },
    ];

    get(): KeyValue<CompanyUserRole, string>[] {
        return this.types;
    }

    getValue(key: CompanyUserRole | null): string {
        if (key === null || key === undefined) {
            return '';
        }

        const result = this.types.find((status) => status.key == key);
        return result ? result.value : '';
    }
}
