import { DestroyRef, Directive, inject, Input, OnInit } from '@angular/core';
import { Table, TableFilterEvent } from 'primeng/table';
import { TableFilteredService } from '../services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({ selector: '[pluTableFiltered]',standalone: true })
export class TableFilteredDirective implements OnInit {
    /*
    * Turn off or on the directive. Default is true;
    */
    @Input('pluTableFiltered') isEnabled = true;
    private readonly destroyRef = inject(DestroyRef);

    constructor(private readonly table: Table, private readonly tableFilteredService: TableFilteredService) {}

    ngOnInit(): void {
        if (this.isEnabled) {
            this.table.onFilter.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((filterEvent: TableFilterEvent) => {
                        this.tableFilteredService.loadPortCalls(filterEvent.filteredValue)
                }
            );
            this.tableFilteredService.loadPortCalls(this.table.filteredValue || this.table.value)
        }
    }
}
