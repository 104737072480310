import { Injectable } from '@angular/core';
import { PortCallExcelExport, PortCallExcelHeaders } from '@port-line-up/shared/util';
import { ExcelHeadersResolverService } from './excel-headers/excel-headers.resolver';
import { ExcelHeadersForTemplateResolverService } from './excel-headers-for-template/excel-headers.resolver';
import { ExcelColumnsResolverService } from './excel-columns/excel-columns.resolver';
import { PortCall } from '@port-line-up/shared/data-access';

@Injectable({ providedIn: 'root' })
export class ExcelExportFacadeService {
    constructor(
        private readonly excelHeaderResolverService: ExcelHeadersResolverService,
        private readonly excelHeaderForTemplateResolverService: ExcelHeadersForTemplateResolverService,
        private readonly excelColumnsResolverService: ExcelColumnsResolverService
    ) {}

    getExcelHeaders(): PortCallExcelHeaders[] {
        return this.excelHeaderResolverService.resolve();
    }

    getExcelHeadersForTemplate(): PortCallExcelHeaders[] {
        return this.excelHeaderForTemplateResolverService.resolve();
    }

    getExcelColumns(portCalls: PortCall[]): PortCallExcelExport[] {
        return this.excelColumnsResolverService.resolve(portCalls);
    }
}
