import { Injectable } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { PortCallPresentation } from '../models';
import { TableSortService } from '@port-line-up/shared/util';

@Injectable({ providedIn: 'root' })
export class PresentationTableSortService {
    constructor(private readonly tableSortService: TableSortService) {}

    sort(event: SortEvent) {
        event?.data?.sort((data1: PortCallPresentation, data2: PortCallPresentation) => {
            if (event.field && event.order) {
                const [value1, value2] = this.tableSortService.getValues(data1, data2, event.field as keyof PortCallPresentation);
                return this.tableSortService.defaultSort(value1, value2, event.order);
            }
            return 1;
        });
    }
}
