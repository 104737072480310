import { Directive, inject } from '@angular/core';
import { AutoComplete } from 'primeng/autocomplete';

@Directive({
  selector: '[pluAutoCompleteColumnFilterCommonSettings]',
  standalone: true,
})
export class AutoCompleteColumnFilterCommonSettingsDirective {
  private readonly autoComplete = inject(AutoComplete);

  constructor () {
    this.autoComplete.virtualScroll = true;
    this.autoComplete.multiple = true;
    this.autoComplete.forceSelection = true;
    this.autoComplete.dropdown = true;
    this.autoComplete.virtualScrollItemSize = 50;
    this.autoComplete.minLength = 3;
    this.autoComplete.size = 6;
    this.autoComplete.styleClass = 'flex flex-wrap max-w-60';
  }
}
