import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TableFilteredStore } from './table-filtered.store';
import { PortCallPresentation } from '../../models';
import { SelectedVessel } from '../../components';
import { LookupItem } from '@port-line-up/shared/util';

@Injectable()
export class TableFilteredService {
    selectedVessel$: Observable<SelectedVessel | null> = this.utilStore.selectedVessel$;
    isMapOpened$: Observable<boolean> = this.utilStore.isMapOpened$;
    isReportOpened$: Observable<boolean> = this.utilStore.isReportOpened$;
    isTrafficChartOpened$: Observable<boolean> = this.utilStore.isTrafficChartOpened$;
    portCalls$: Observable<PortCallPresentation[]> = this.utilStore.portCalls$;
    availableCargos$: Observable<LookupItem[]> = this.utilStore.availableCargos$;
    isCargoChartOpened$: Observable<boolean> = this.utilStore.isCargoChartOpened$;
    selectedCargo$: Observable<LookupItem | null> = this.utilStore.selectedCargo$;

    constructor(private readonly utilStore: TableFilteredStore) { }

    toggleChart(): void {
        this.utilStore.toggleChart();
    }

    loadPortCalls(portCalls: PortCallPresentation[]): void {
        this.utilStore.loadPortCalls(portCalls);
    }

    toggleMap(): void {
        this.utilStore.toggleMap();
    }

    toggleReport(): void {
        this.utilStore.toggleReport();
    }

    toggleCargoChart(): void {
        this.utilStore.toggleCargoChart();
    }

    selectCargo(selectedCargo: LookupItem | null): void {
        this.utilStore.selectCargo(selectedCargo);
    }

    selectVessel(selectedVessel: SelectedVessel | null): void {
        this.utilStore.selectVessel(selectedVessel);
    }

    closeUtils(): void {
        this.utilStore.closeUtils();
    }
}
