export class LookupItem implements Lookup {
    constructor(public readonly id: string, public readonly displayText: string) {}
    static create(id: string, displayText: string): LookupItem {
        return new LookupItem(id, displayText);
    }

    static createEmpty(): LookupItem {
        return new LookupItem('', '');
    }

    /**
     * 
     * @param id : string | null
     * @param displayText : string | null
     * @returns LookupItem if id is not null, otherwise null
     */
    static createIfValid(id: string | null, displayText: string | null): LookupItem | null {
        return id ? LookupItem.create(id, displayText ?? '') : null;
    }

    /**
     * 
     * @param id : string | null
     * @param displayText : string | null
     * @returns LookupItem if id is not null and displayText is not null, otherwise LookupItem with empty string values
     */
    static createWithEmptyFallback(id: string | null, displayText: string | null): LookupItem {
        return LookupItem.create(id ?? '', displayText ?? '');
    }
}

export interface Lookup {
    id: string;
    displayText: string;
}
