import { Injectable, Renderer2 } from '@angular/core';
import { Table } from 'primeng/table';
import { Observable, Subject } from 'rxjs';
import { ScrollingToRowData } from '../models';
import { ScrollableContainerService } from './scrollable-container.service';

@Injectable()
export class RowScrollService {
    constructor(private readonly scrollableContainerService: ScrollableContainerService, private readonly renderer: Renderer2) {}

    private readonly pageChangedSubject = new Subject<void>();

    get pageChanged(): Observable<void> {
        return this.pageChangedSubject.asObservable();
    }

    handleScrollingToSpecificRow<T>(
        tableRef: Table,
        data: ScrollingToRowData<T>,
    ): void {

        if (!data.rowIdentifier.value) {
            this.removeClassFromAllTableRows(tableRef, data.styleClass)
            return;
        }

        const { heightOffset, styleClass, rowIdentifier, notFoundCallback } = data;
        const rows = tableRef.paginator ? (tableRef.rows ?? 50) : tableRef.value.length;
        const specificRowIndex = tableRef.value.findIndex((item) => item[rowIdentifier.key] === rowIdentifier.value);
        if (specificRowIndex === -1) {
            if(notFoundCallback) {
                notFoundCallback(data);
            }
            return;
        }

        const specificRowPage = Math.floor((specificRowIndex) / rows);
        this.navigateToPage(specificRowPage, rows, tableRef);

        const chunkedTableRows = this.getChunkedTableRows(tableRef, rows, specificRowPage);
        if (!this.haveRowsSpecificRowIdentifierKey<T>(chunkedTableRows, rowIdentifier)) return;

        const specificRowOffset = this.getOffset<T>(chunkedTableRows, rowIdentifier, styleClass);
        this.scrollTo(specificRowOffset - heightOffset);
    }

    private removeClassFromAllTableRows(tableRef: Table, styleClass: string): void {
        const allTableRows: HTMLTableRowElement[] = Array.from(tableRef.el.nativeElement.querySelectorAll('tr'));
        allTableRows.forEach((tableRow: HTMLTableRowElement) => {
            styleClass.split(' ').forEach((styleClass: string) =>  this.renderer.removeClass(tableRow, styleClass));
        });
    }

    private readonly scrollTo = (top: number, behavior: ScrollBehavior = 'smooth'): void => {
        this.scrollableContainerService.scrollTo({ top, behavior });
    };

    /* When we select vessel on map, and it is on some different page, table generate table rows but sometimes don't
       remove old ones, so we select only select that are rendered in view (last 50 or less from "allTableRows" array). */

    private getChunkedTableRows(tableRef: Table, rowsPerPage: number, page: number): HTMLTableRowElement[] {
        const allTableRows: HTMLTableRowElement[] = Array.from(tableRef.el.nativeElement.querySelectorAll('tr'));
        const totalItemsCount: number = tableRef.value.length;

        const itemsInSpecificPageCount: number = Math.min(rowsPerPage, totalItemsCount - (page * rowsPerPage));
        return allTableRows.slice(allTableRows.length - itemsInSpecificPageCount);
    }

    private haveRowsSpecificRowIdentifierKey<T>(
        chunkedTableRows: HTMLTableRowElement[],
        rowIdentifier: {
            key: keyof T;
            value: string;
        }
    ): boolean {
        return chunkedTableRows[1].getAttribute((rowIdentifier.key as string).toLowerCase()) !== null;
    }

    private getOffset<T>(
        chunkedTableRows: HTMLTableRowElement[],
        rowIdentifier: {
            key: keyof T;
            value: string;
        },
        styleClass: string
    ): number {
        let offset = 0;
        let calculateOffsetFlag = true;

        for (const row of chunkedTableRows) {
            if (row.getAttribute((rowIdentifier.key as string).toLowerCase()) === rowIdentifier.value) {
                styleClass.split(' ').forEach((styleClass) =>  this.renderer.addClass(row, styleClass));
                calculateOffsetFlag = false;
            } else {
                styleClass.split(' ').forEach((styleClass) =>  this.renderer.removeClass(row, styleClass));
            }

            if (calculateOffsetFlag) {
                offset += row.offsetHeight;
            }
        }

        return offset;
    }


    private navigateToPage(pageOnWhichSpecificRowIs: number, rows: number, tableRef: Table): void {
        tableRef.first = (tableRef.first ?? 0) + pageOnWhichSpecificRowIs * rows;
        tableRef.cd.detectChanges();

        this.pageChangedSubject.next();
    }
}

