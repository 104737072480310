import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, inject, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '@port-line-up/shared/ui/header';
import { Port, SignalRPortUpdateService, UIStore } from '@port-line-up/shared/data-access';
import { Observable, first, map } from 'rxjs';
import {
    AccordionHeaderHeightDirective,
    AccordionHeaderSetterDirective,
    ActionPendingInterceptor,
    GRID_COLUMN_PROVIDER,
    GRID_FILTER_PROVIDER,
    GridColumnService,
    ScrollableContainerService,
    SliderService,
} from '@port-line-up/shared/util';
import { DialogService } from 'primeng/dynamicdialog';
import { SharedViewGridColumnsFactoryService } from './shared-view/services/grid-columns.factory';
import { AccordionModule } from 'primeng/accordion';
import { ActivatedRoute } from '@angular/router';
import { GridColumnSelectorComponent } from '@port-line-up/shared/ui/grid-column-selector';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { SharedViewComponent } from './shared-view/shared-view.component';
import { SharedViewStore } from './shared-view.store';
import {
    DATA_UPDATE_PROVIDER,
    FilterChipsService,
    GridFilterConfigurationFactory,
    TABLE_FILTERED_PROVIDERS,
} from '@port-line-up/shared/feature-presentation-table';
import { TableHorizontalScrollBarComponent } from '@port-line-up/shared/ui/table-horizontal-scroll-bar';
import { InputSwitchModule } from 'primeng/inputswitch';
import { Asset } from './shared-view/models/assets';
import { ScrollableContainerComponent } from '@port-line-up/shared/ui/scrollable-container';

@Component({
    selector: 'plu-feature-shared-view',
    standalone: true,
    imports: [
        CommonModule,
        HeaderComponent,
        AccordionModule,
        ButtonModule,
        DividerModule,
        SharedViewComponent,
        AccordionHeaderSetterDirective,
        TableHorizontalScrollBarComponent,
        InputSwitchModule,
        AccordionHeaderHeightDirective,
        ScrollableContainerComponent
    ],
    templateUrl: './port-line-up-feature-shared-view.component.html',
    styleUrls: ['./port-line-up-feature-shared-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        SignalRPortUpdateService,
        DATA_UPDATE_PROVIDER(SharedViewStore),
        GRID_COLUMN_PROVIDER(SharedViewGridColumnsFactoryService, undefined, true),
        GRID_FILTER_PROVIDER(GridFilterConfigurationFactory),
        DialogService,
        SharedViewStore,
        ActionPendingInterceptor,
        FilterChipsService,
        SliderService,
        TABLE_FILTERED_PROVIDERS,
        ScrollableContainerService
    ],
})
export class PortLineUpFeatureSharedViewComponent implements OnInit, AfterViewInit {
    @ViewChild(HeaderComponent, { read: ElementRef }) headerComponent!: ElementRef;

    port$: Observable<Port>;
    id$: Observable<string>;
    assets$: Observable<Asset[]>;
    showReport$: Observable<boolean>;

    accordionHeaderHeight: WritableSignal<number> = signal<number>(0);

    private readonly uiStore = inject(UIStore);

    constructor(
        private readonly dialogService: DialogService,
        private readonly gridColumnService: GridColumnService,
        private readonly gridColumnFactory: SharedViewGridColumnsFactoryService,
        private readonly signalRService: SignalRPortUpdateService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly sharedViewStore: SharedViewStore
    ) {
        const data = this.activatedRoute.data.pipe(map((x) => x['data']));
        this.sharedViewStore.setId(this.activatedRoute.params.pipe(map((p) => p['id'])));
        this.sharedViewStore.setToken(this.activatedRoute.params.pipe(map((p) => p['token'])));
        this.sharedViewStore.setFilters(data.pipe(map((data) => data.filters)));
        const portCalls$ = data.pipe(map((data) => data.portCallsDto.items));
        this.sharedViewStore.getPortCallsSuccess(portCalls$);
        this.port$ = data.pipe(map((data) => data.port));
        this.sharedViewStore.getPortSuccess(this.port$);
        this.assets$ = data.pipe(map((data) => data.assets));
        this.sharedViewStore.getAssetsSuccess(this.assets$);
        this.showReport$ = data.pipe(map((data) => data.showReport));
        this.sharedViewStore.getShowReportSuccess(this.showReport$);
        this.id$ = this.sharedViewStore.id$;
    }

    ngOnInit(): void {
        this.id$.pipe(first()).subscribe((id) => {
            this.signalRService.subscribeToPortUpdate(id, 'sharedLinkPortsHub');
        });
    }

    ngAfterViewInit(): void {
        this.uiStore.setHeaderHeight(this.headerComponent.nativeElement.offsetHeight);
    }

    openGridSettingsModal() {
        const ref = this.dialogService.open(GridColumnSelectorComponent, {
            header: 'Column Chooser',
            width: '700px',
            height: 'auto',
            data: {
                visibleColumns: this.gridColumnService.getColumns(),
                availableColumns: this.gridColumnFactory.create(),
            },
        });

        ref.onClose.subscribe((result) => {
            if (result) {
                this.gridColumnService.saveColumns(result);
            }
        });
    }

    resetGrid() {
        this.gridColumnService.resetColumns();
    }

    setAccordionHeaderHeight(accordionHeaderHeight: number): void {
        this.accordionHeaderHeight.set(accordionHeaderHeight);
    }
}
