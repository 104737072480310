import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_ENVIRONMENT } from '@port-line-up/shared/util';
import { ExpressionOperator, FilterLogicalOperator } from '../enums';
import { BulkSaveOngoing, FilterItem, PortCall, EntityListResult, PortCallStatus, BulkSaveSailed } from '../models';

@Injectable({ providedIn: 'root' })
export class PortCallService {
    private readonly url = `${this.appEnvironment.baseApiUrl}/portcalls`;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(private readonly http: HttpClient, @Inject(APP_ENVIRONMENT)
    private readonly appEnvironment: any) {}

    getActiveByUnloCode(unloCode: string | null): Observable<EntityListResult<PortCall>> {
        if (!unloCode) {
            throw new Error('Unlo code is required');
        }

        const queryString = FilterItem.create('unlocode', unloCode).getHttpParams(0);
        return this.http.get<EntityListResult<PortCall>>(`${this.url}/active/?${queryString}`);
    }

    getSailedByUnloCode(unloCode: string | null): Observable<EntityListResult<PortCall>> {
        if (!unloCode) {
            throw new Error('Unlo code is required');
        }

        const queryString = FilterItem.create('unlocode', unloCode).getHttpParams(0);
        return this.http.get<EntityListResult<PortCall>>(`${this.url}/sailed/?${queryString}`);
    }

    getActiveByUnloCodes(unloCodes: string[]): Observable<EntityListResult<PortCall>> {
        if (!unloCodes || unloCodes.length === 0) {
            throw new Error('Unlo codes are required');
        }

        let httpParams = new HttpParams();
        unloCodes.forEach((unloCode, index) => {
            httpParams = FilterItem.create('unlocode', unloCode, ExpressionOperator.Equals, FilterLogicalOperator.OR).updateHttpParams(httpParams, index);
        });

        return this.http.get<EntityListResult<PortCall>>(`${this.url}/active/?${httpParams}`);
    }

    bulkSaveOngoing(items: BulkSaveOngoing): Observable<void> {
        return this.http.post<void>(`${this.url}/bulkSave`, items);
    }

    bulkSaveSailed(items: BulkSaveSailed): Observable<void> {
        return this.http.post<void>(`${this.url}/bulkSaveSailed`, items);
    }

    getPortCallStatusesByUnloCode(unloCodes: string[]): Observable<PortCallStatus[]> {
        if (!unloCodes) {
            throw new Error('Unlo codes are required');
        }

        return this.http.get<PortCallStatus[]>(`${this.url}/portStatus/`, { params: { unlocodes: unloCodes } });
    }
}
