import { Injectable } from '@angular/core';
import { DateService } from '@port-line-up/shared/util';
import { AddedPortCalls } from '../models/changed-port-calls';

@Injectable()
export class AddedDataService {
    constructor(private readonly date: DateService) {}

    add(id: string, addedMap: AddedPortCalls): void {
        if (addedMap.has(id)) {
            return;
        }

        addedMap.set(id, this.date.now());
    }

    remove(addedMap: AddedPortCalls, after: number): AddedPortCalls {
        const seconds = after * 1000;
        const currentTime = this.date.now();

        for (const [id, time] of addedMap.entries()) {
            const shouldRemove = time + seconds < currentTime;
            if (shouldRemove) {
                addedMap.delete(id);
            }
        }

        return addedMap;
    }
}
