import { HttpParams } from '@angular/common/http';
import { PaginationItem } from './pagination-item';
import { SortItem } from './sort-item';
import { FilterMetadata } from 'primeng/api';
import { FilterItem } from './filter-item';
import { ExpressionOperator } from '../enums';
import { CustomFilters } from './custom-table-filter';
import { TableLazyLoadEvent } from 'primeng/table';

export class TableHttpParamsBuilder {
    private httpParams: HttpParams;
    private startFilterIndex = 0;

    constructor(httpParams?: HttpParams, startFilterIndex?: number) {
        if(httpParams) {
            this.httpParams = httpParams;
        } else {
            this.httpParams = new HttpParams();
        }

        if(startFilterIndex) {
            this.startFilterIndex = startFilterIndex
        } else {
            this.startFilterIndex = 0;
        }
    }

    addPagination(pageIndex: number, pageSize: number) {
        this.httpParams = PaginationItem.create(pageIndex, pageSize).updateHttpParams(this.httpParams);
        return this;
    }

    addSorting(lazyLoadEvent?: TableLazyLoadEvent) {
        if (lazyLoadEvent?.sortField && lazyLoadEvent?.sortOrder) {
            this.httpParams = SortItem.create(lazyLoadEvent.sortField as string, lazyLoadEvent.sortOrder).updateHttpParams(this.httpParams, 0);
        }
        return this;
    }

    addFiltering(lazyLoadEvent?: TableLazyLoadEvent, customFilters?: CustomFilters) {
        if (customFilters && !lazyLoadEvent?.filters) {
            customFilters.forEach((filter, index) => (this.httpParams = filter.updateHttpParams(this.httpParams, index)));
            return this;
        }

        if (lazyLoadEvent?.filters) {
            this.extractFiltersFromLazyLoadEventAndCreateHttpParams(lazyLoadEvent);
            
            if (customFilters) {
                customFilters.forEach((filter) => {
                    this.httpParams = filter.updateHttpParams(this.httpParams, this.startFilterIndex);
                    this.startFilterIndex++;
                });
            }
        }

        return this;
    }

    getHttpParams(): HttpParams {
        return this.httpParams;
    }

    private mapMatchMode(matchMode?: string): ExpressionOperator {
        switch (matchMode) {
            case 'Contains':
                return ExpressionOperator.Contains;
            case 'Equals':
                return ExpressionOperator.Equals;
            case 'Before':
                return ExpressionOperator.LessOrEqual;
            case 'After':
                return ExpressionOperator.GreaterOrEquals;
            default:
                throw new Error('Match mode cannot be matched!');
        }
    }

    private extractFiltersFromLazyLoadEventAndCreateHttpParams(lazyLoadEvent: TableLazyLoadEvent) {
        for (const key in lazyLoadEvent.filters) {
            if (Object.prototype.hasOwnProperty.call(lazyLoadEvent.filters, key)) {
                const elements = lazyLoadEvent.filters[key] as FilterMetadata[];
                for (const element of elements) {
                    if (element.value === null || element.value === undefined || element.value === '') {
                        continue;
                    }
                    this.httpParams = FilterItem.create(key, element.value, this.mapMatchMode(element.matchMode)).updateHttpParams(this.httpParams, this.startFilterIndex);
                    this.startFilterIndex++;
                }
            }
        }
    }
}
