import { Injectable } from '@angular/core';
import { Observable, filter, forkJoin, tap } from 'rxjs';
import { PortStore } from '../+state/port.store';
import { Port } from '../models';
import { PortStorage } from '../storage';

@Injectable({ providedIn: 'root' })
export class PortFacadeService {
    unloCodes$: Observable<string[]> = this.portStore.unloCodes$;
    ports$: Observable<Port[]> = this.portStore.ports$;
    selectedUnloCode$: Observable<string | null> = this.portStore.selectedUnloCode$;
    selectedPort$: Observable<Port> = this.portStore.selectedPort$;

    constructor(private readonly portStore: PortStore, private readonly portStorage: PortStorage) { }

    initialize(): Observable<[string[], string]> {
        return forkJoin([
            this.loadInitialUnloCodes(),
            this.loadInitialSelectedUnloCode()
        ]).pipe(tap(() => this.getPortsOnUnloCodeChanges()));
    }

    setUnloCodes(unloCodes: string[]): void {
        this.portStore.saveUnloCodes(unloCodes);
    }

    setSelectedUnloCode(unloCode: string): void {
        this.portStore.saveSelectedUnloCode(unloCode);
    }

    private getPortsOnUnloCodeChanges(): void {
        this.portStore.unloCodes$.pipe(
            filter(unloCodes => unloCodes.length > 0)
        ).subscribe((unloCodes) => this.portStore.getPorts(unloCodes));
    }

    private loadInitialUnloCodes(): Observable<string[]> {
        return this.portStorage.getUnloCodes().pipe(tap(unloCodes => this.portStore.loadUnloCodes(unloCodes)))
    }

    private loadInitialSelectedUnloCode(): Observable<string> {
        return this.portStorage.getSelectedUnloCode().pipe(tap(unloCode => this.portStore.loadSelectedUnloCode(unloCode)))
    }
}