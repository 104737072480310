import { Injectable } from '@angular/core';
import { PortCall } from '@port-line-up/shared/data-access';
import { DateService } from '@port-line-up/shared/util';
import { ChangedPortCalls } from '../models/changed-port-calls';

@Injectable()
export class ChangedDataService {
    constructor(private readonly date: DateService) {}

    add(id: string, propertyName: keyof PortCall, editedMap: ChangedPortCalls): void {
        const time = this.date.now();

        const propertyMap = editedMap.get(id);
        if (!propertyMap) {
            const propertyMap = new Map<keyof PortCall, number[]>();
            propertyMap.set(propertyName, [time]);
            editedMap.set(id, propertyMap);
            return;
        }

        if (propertyMap?.has(propertyName)) {
            const entries = propertyMap.get(propertyName);
            entries?.push(time);
        } else {
            propertyMap?.set(propertyName, [time]);
        }
    }

    remove(editedMap: ChangedPortCalls, after: number): ChangedPortCalls {
        const seconds = after * 1000;
        const currentTime = this.date.now();

        for (const [cellId, propertyMap] of editedMap.entries()) {
            for (const [key, changes] of propertyMap.entries()) {
                const filteredChanges = changes.filter((time) => time + seconds >= currentTime);

                if (filteredChanges.length === 0) {
                    propertyMap.delete(key);
                } else {
                    propertyMap.set(key, filteredChanges);
                }
            }

            if (propertyMap.size === 0) {
                editedMap.delete(cellId);
            }
        }
        return editedMap;
    }
}
