import { MenuItem, MenuItemCommandEvent } from 'primeng/api';

export class TableSettingsMenuBuilder {
    private readonly menuItems: MenuItem[];

    constructor() {
        this.menuItems = [];
    }

    addGridSettingsMenuItem(callback: (event: MenuItemCommandEvent) => void): this {
        const menuItem: MenuItem = {
            label: `Grid Settings`,
            icon: 'pi pi-table sm-max:mr-1 text-blue-500',
            escape: false,
            command: callback,
        };
        this.menuItems.push(menuItem);
        return this;
    }

    addResetSettingsMenuItem(callback: (event: MenuItemCommandEvent) => void): this {
        const menuItem: MenuItem = {
            label: `Reset Settings`,
            icon: 'pi pi-reply sm-max:mr-1 text-blue-500',
            escape: false,
            command: callback,
        };
        this.menuItems.push(menuItem);
        return this;
    }

    addMenuItem(menuItem: MenuItem): this {
        this.menuItems.push(menuItem);
        return this;
    }

    getMenuItems(): MenuItem[] {
        return this.menuItems;
    }
}
