import { Directive, ElementRef, AfterViewInit, DestroyRef, inject } from '@angular/core';
import { distinctUntilChanged, filter, fromEvent } from 'rxjs';
import { SliderService } from '@port-line-up/shared/util';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({ selector: '[pluHorizontalScroll]', standalone: true })
export class HorizontalScrollDirective implements AfterViewInit {
    private scrollLeft = 0;
    private readonly destroyRef = inject(DestroyRef);
    constructor(private readonly div: ElementRef<HTMLDivElement>, private readonly sliderService: SliderService) {}

    ngAfterViewInit(): void {
        this.listenToScrollContainerScrollEvent()
        this.subscribeToTableScrollLeftValueChange();
    }

    private subscribeToTableScrollLeftValueChange(): void {
        this.sliderService.tableScrollLeft$.pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged()).subscribe((scrollLeft) => {
            if (typeof scrollLeft === "number" && this.scrollLeft !== scrollLeft) {
                this.scrollLeft = scrollLeft;
                this.div.nativeElement.scrollLeft = scrollLeft;
            }
        });
    }

    private listenToScrollContainerScrollEvent(): void {
        fromEvent(this.div.nativeElement, 'scroll')
            .pipe(takeUntilDestroyed(this.destroyRef), filter(wrapper => {
                const { scrollLeft } = (wrapper as any).target;
                if (scrollLeft === this.scrollLeft) {
                    return false;
                }
                this.scrollLeft = scrollLeft;
                return true;
            })).subscribe(( wrapper ) => {
            const { scrollLeft } = (wrapper as any).target;
            this.sliderService.tableScrollLeft(scrollLeft);
        });
    }
}
