import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class NotificationService {
    private readonly notificationSubject = new Subject<unknown>();
    notification$ = this.notificationSubject.asObservable();

    notify(event: unknown): void {
        this.notificationSubject.next(event);
    }    
}

