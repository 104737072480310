import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_ENVIRONMENT, LookupItem } from '@port-line-up/shared/util';

@Injectable({ providedIn: 'root' })
export class OperationService {
    private readonly baseUrl = `${this.appEnvironment.baseApiUrl}/operation`;

    constructor(private readonly http: HttpClient, @Inject(APP_ENVIRONMENT)
    private readonly appEnvironment: any) {}

    get(): Observable<LookupItem[]> {
        return this.http.get<LookupItem[]>(`${this.baseUrl}/lookup`);
    }
}
