<div class="shadow-sm pr-3 wrapper sticky w-full top-0 left-0 z-50 py-3">
  <div class="plu-container">
    <div class="flex items-center">
      <a
        class="flex items-center grow-0 cursor-pointer"
        [routerLink]="['/ports']"
      >
        <img src="assets/images/gacLogoWhite.svg" height="36" width="39" alt="gacLogoWhite" />
        <div class="flex-shrink-0 font-bold text-xl ml-2">
          <span>Port Line Up</span>
        </div>
      </a>
      <ng-content></ng-content>
    </div>
  </div>
</div>
