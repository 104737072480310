import { PortCallExcelExport } from '@port-line-up/shared/util';
import { ExcelColumnsResolver } from '../excel-columns-resolver';
import { BerthDelayService, PortCall } from '@port-line-up/shared/data-access';

export class ExcelColumnsDefaultResolver extends ExcelColumnsResolver {
    constructor(private readonly berthDelayService: BerthDelayService) {
        super();
    }
    override canResolve(): boolean {
        return true;
    }

    override resolve(portCalls: PortCall[], ): PortCallExcelExport[] {
        return this.sortPortCalls(portCalls).map((portCall) => {
            return {
                priority: portCall.priority,
                vesselName: portCall.vesselName,
                imoNumber: portCall.imoNumber,
                vesselType: portCall.vesselType,
                voyageNumber: portCall.voyageNumber,
                vesselStatus: portCall.vesselStatus,
                portName: portCall.portName,
                portUnloCode: portCall.unlocode,
                terminalName: portCall.terminalName,
                berthName: portCall.berthName,
                berthPriority: portCall.berthPriority,
                eta: portCall.eta ? new Date(portCall.eta).toLocaleString() : null,
                etaihs: portCall.etaihs ? new Date(portCall.etaihs).toLocaleString() : null,
                atb: portCall.atb ? new Date(portCall.atb).toLocaleString() : null,
                atbihs: portCall.atbihs ? new Date(portCall.atbihs).toLocaleString() : null,
                atd: portCall.atd ? new Date(portCall.atd).toLocaleString() : null,
                atdihs: portCall.atdihs ? new Date(portCall.atdihs).toLocaleString() : null,
                ata: portCall.ata ? new Date(portCall.ata).toLocaleString() : null,
                ataihs: portCall.ataihs ? new Date(portCall.ataihs).toLocaleString() : null,
                etb: portCall.etb ? new Date(portCall.etb).toLocaleString() : null,
                ecc: portCall.ecc ? new Date(portCall.ecc).toLocaleString() : null,
                acc: portCall.acc ? new Date(portCall.acc).toLocaleString() : null,
                etc: portCall.etc ? new Date(portCall.etc).toLocaleString() : null,
                atc: portCall.atc ? new Date(portCall.atc).toLocaleString() : null,
                etd: portCall.etd ? new Date(portCall.etd).toLocaleString() : null,
                operationName: portCall.operationName,
                notes: portCall.notes,
                dwt: portCall.dwt,
                berthDelay: this.berthDelayService.getBerthDelay(portCall),
                nextDestinationNote: portCall.nextDestinationNote,
                cargos: this.resolveCargos(portCall.cargos),
            } as PortCallExcelExport;
        });
    }


}
