import { Injectable } from '@angular/core';
import { VesselLocation } from '../models/vessel-location';
import { PortCallMapTimeIndicator } from '../models/port-call-map-time-indicator';
import { DatesBackgroundColor, LookupItem } from '@port-line-up/shared/util';
import { PortCallPresentation } from '../../../models';

@Injectable()
export class VesselLocationFactoryService {
    create(portCalls: PortCallPresentation[]): VesselLocation[] {
        return portCalls
            .filter((x) => x.latitude !== null && x.longitude !== null)
            .map((x) => {
                return {
                    portCallId: x.id ?? '',
                    imo: +x.imoNumber,
                    lat: x.latitude,
                    lng: x.longitude,
                    heading: +x.heading,
                    name: x.vesselName,
                    status: x.vesselStatus,
                    portCallMapTimeIndicator: this.handlePortCallMapTimeIndicator(x),
                    cargoType: this.handleCargoData(x.cargoType),
                    cargoQuantity: this.handleCargoData(x.cargoQuantity),
                    cargoQuantityUomName: this.handleCargoData(x.cargoQuantityUomName),
                } as VesselLocation;
            });
    }

    handleCargoData(cargoData: (LookupItem | number | string | null)[]): (LookupItem | number | string | null)[] {
        if (cargoData.length === 1 && !cargoData[0]) {
            return [];
        }

        return cargoData;
    }

    handlePortCallMapTimeIndicator(portCall: PortCallPresentation): PortCallMapTimeIndicator | null {
        if (portCall.atd) {
            return new PortCallMapTimeIndicator(this.getLabelHtml('ATD'), portCall.atd, DatesBackgroundColor.atd);
        }

        if (portCall.atc) {
            return new PortCallMapTimeIndicator(this.getLabelHtml('ATC'), portCall.atc, DatesBackgroundColor.atc);
        }

        if (portCall.acc) {
            return new PortCallMapTimeIndicator(this.getLabelHtml('ACC'), portCall.acc, DatesBackgroundColor.acc);
        }

        if (portCall.atb) {
            return new PortCallMapTimeIndicator(this.getLabelHtml('ATB'), portCall.atb, DatesBackgroundColor.atb);
        }

        if (portCall.ata) {
            return new PortCallMapTimeIndicator(this.getLabelHtml('ATA'), portCall.ata, DatesBackgroundColor.ata);
        }

        if (portCall.ataihs) {
            return new PortCallMapTimeIndicator(this.getLabelHtmlIhs('ATA'), portCall.ataihs, `${DatesBackgroundColor.ata} pt-0.5`);
        }

        if (portCall.etd) {
            return new PortCallMapTimeIndicator(this.getLabelHtml('ETD'), portCall.etd, DatesBackgroundColor.etd);
        }

        if (portCall.etc) {
            return new PortCallMapTimeIndicator(this.getLabelHtml('ETC'), portCall.etc, DatesBackgroundColor.etc);
        }

        if (portCall.ecc) {
            return new PortCallMapTimeIndicator(this.getLabelHtml('ECC'), portCall.ecc, DatesBackgroundColor.ecc);
        }

        if (portCall.etb) {
            return new PortCallMapTimeIndicator(this.getLabelHtml('ETB'), portCall.etb, DatesBackgroundColor.etb);
        }

        if (portCall.eta) {
            return new PortCallMapTimeIndicator(this.getLabelHtml('ETA'), portCall.eta, DatesBackgroundColor.eta);
        }

        if (portCall.etaihs) {
            return new PortCallMapTimeIndicator(this.getLabelHtmlIhs('ETA'), portCall.etaihs, `${DatesBackgroundColor.eta} pt-0.5`);
        }

        return null;
    }

    private getLabelHtml(dateType: string): string {
        return `<img src="assets/images/agent.svg" alt="agent" class="mr-1 pb-0.5"/> <span>${dateType}</span>`;
    }

    private getLabelHtmlIhs(ihsType: string): string {
        return `<i class="pi pi-wifi text-white mr-1 pb-0.5"></i> <span>${ihsType}</span>`;
    }
}
