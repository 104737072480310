import { DestroyRef, Directive, inject, OnInit } from '@angular/core';
import { Table } from 'primeng/table';
import { RowScrollService, ScrollingToRowData } from '@port-line-up/shared/util';
import { ScrollingToRowDataFactory, TableFilteredService } from '../services';
import { PortCallPresentation } from '../models';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SelectedVessel } from '../components';

@Directive({ selector: '[pluScrollingToSpecificRowByVesselMapClickDirective]', standalone: true })
export class ScrollingToSpecificRowByVesselMapClickDirective implements OnInit {

    private readonly destroyRef: DestroyRef = inject(DestroyRef);
    constructor(private readonly tableRef: Table,
                private readonly rowScrollService: RowScrollService,
                private readonly tableFilteredService: TableFilteredService,
                private readonly scrollingToRowDataFactory: ScrollingToRowDataFactory) {}

    ngOnInit(): void {
        this.subscribeOnMapVesselClick();
    }

    private subscribeOnMapVesselClick(): void {
        this.tableFilteredService.selectedVessel$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((result: SelectedVessel | null) => {
            if (result?.imoNumber && !result.isSelected) {
                this.handleScrolling(this.scrollingToRowDataFactory.create(result.imoNumber));
            } else {
                this.rowScrollService
                    .handleScrollingToSpecificRow<PortCallPresentation>(this.tableRef, this.scrollingToRowDataFactory.create(null));
            }
        });
    }

    private handleScrolling(scrollingToRowData: ScrollingToRowData<PortCallPresentation>): void {
        this.tableRef.first = null;
        this.tableRef.cd.detectChanges()
        this.rowScrollService.handleScrollingToSpecificRow<PortCallPresentation>(this.tableRef, scrollingToRowData);
    }
}
