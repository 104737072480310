import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, concatMap, map, Observable, of, switchMap } from 'rxjs';
import { GRID_FILTER_STORAGE_KEY} from './storage-key.token';
import { FilterMatchMode, FilterMetadata } from 'primeng/api';
import { CustomMatchMode, GridFilter, GridFilterItem, ModifyTableFilterIds, PartyFilter, PresentationTableFilterIds } from '../../models';
import { GridFilterFactory } from './grid-filter-factory';
import { StorageService } from '../storage.service';

@Injectable()
export class GridFilterReactiveService {

    private readonly portCallFilterSubject = new BehaviorSubject<GridFilter | null>(null);
    portCallFilters$: Observable<GridFilter | null> = this.portCallFilterSubject.asObservable();

    private filters: GridFilter[] = [];
    constructor(private readonly storageService: StorageService,
                private readonly gridFilterFactory: GridFilterFactory,
                @Inject(GRID_FILTER_STORAGE_KEY)
                private readonly key: string) {}

    write(newPortCallFilter: GridFilter): Observable<void> {
        const portCallFilter = this.filters.find(x => x.key === newPortCallFilter.key);
        if (portCallFilter) {
            portCallFilter.filters = newPortCallFilter.filters;
        } else {
            this.filters.push(newPortCallFilter);
        }

        this.portCallFilterSubject.next(newPortCallFilter);
        return this.saveToLocalStorage();
    }

    writeFilterFromQueryParams(key: string, filtersFromQuery: GridFilterItem): Observable<void> {

        const filterIdsFromQuery: string[] = Object.keys(filtersFromQuery);
        const filter: GridFilter | undefined = this.filters.find((x: GridFilter): boolean => x.key === key);

        if (filter?.filters && filterIdsFromQuery  && filterIdsFromQuery.length > 0) {
            return this.clearBy(key).pipe(
                concatMap(() => {
                    filterIdsFromQuery.forEach((filterIdFromQuery: string): void => {
                        if(filter?.filters) {
                            filter.filters[filterIdFromQuery] = filtersFromQuery[filterIdFromQuery]
                        }
                    })
                    return this.saveToLocalStorage();
                })
            );
        }
        return of();
    }
    
    clearBy(key: string): Observable<void> {
        const clearFilter = this.createGridFilter(key);
        this.filters = this.filters.map(f => f.key === key ? clearFilter : f);
        this.portCallFilterSubject.next(clearFilter);
        return this.saveToLocalStorage();
    }

    clearFilter(key: string, filterId: string): Observable<void> {
        const filter = this.filters.find(x => x.key === key);
        if (filter?.filters) {
            (filter.filters[filterId] as FilterMetadata[]).forEach((filterMetaData: FilterMetadata) => {
                if (filterId === PresentationTableFilterIds.party ||
                    filterId === ModifyTableFilterIds.party) {
                    filterMetaData.value = new PartyFilter();
                } else if(filterMetaData.matchMode === CustomMatchMode.EmptyDate) {
                    filterMetaData.matchMode = FilterMatchMode.DATE_IS;
                } else {
                    filterMetaData.value = null;
                }
            });
            this.portCallFilterSubject.next(filter);
            return this.saveToLocalStorage();
        }
        return of();
    }

    readBy(key: string): Observable<GridFilterItem | undefined> {
        return this.storageService.getItem(this.key).pipe(
            switchMap((filters: string | null) => {
                if (!filters) {
                    const newGridFilter: GridFilter = this.createGridFilter(key);
                    this.filters = [newGridFilter];
                    return this.saveToLocalStorage().pipe(
                        map((_: void) => this.handleBeforeReturn(newGridFilter))
                    );
                }
                this.filters = JSON.parse(filters) as GridFilter[];
                const portCallFilter = this.filters.find(x => x.key === key);

                if (!portCallFilter) {
                    const newGridFilter = this.createGridFilter(key);
                    this.filters = [...this.filters, newGridFilter];
                    return this.saveToLocalStorage().pipe(
                        map((_: void) => this.handleBeforeReturn(newGridFilter))
                    );
                }

                return of(this.handleBeforeReturn(portCallFilter));
            })
        )
    }

    private saveToLocalStorage(): Observable<void> {
        if (this.key) {
            return this.storageService.setItem(this.key, JSON.stringify(this.filters));
        }
        return of();
    }

    private handleBeforeReturn(gridFilter: GridFilter):GridFilterItem | undefined{
        this.convertDateFiltersToDate(gridFilter);
        this.portCallFilterSubject.next(gridFilter);
        return gridFilter.filters;
    }

    private convertDateFiltersToDate(portCallFilter: GridFilter): void {
        const dateFiltersNames  = [PresentationTableFilterIds.ata, PresentationTableFilterIds.atb, PresentationTableFilterIds.acc, PresentationTableFilterIds.atd, PresentationTableFilterIds.atc,
                ModifyTableFilterIds.ata, ModifyTableFilterIds.atb, ModifyTableFilterIds.acc, ModifyTableFilterIds.atc, ModifyTableFilterIds.atd];
        dateFiltersNames.forEach((dateFilterName) => {
            if(portCallFilter.filters && (portCallFilter.filters[dateFilterName] as FilterMetadata[])) {
                (portCallFilter.filters[dateFilterName] as FilterMetadata[]).forEach((portCallFilter) => {
                    portCallFilter.value = this.convertStringToDate(portCallFilter.value);
                })
            }
        });
    }

    private convertStringToDate(date: string | null): Date | null | string {
        return date ? new Date(date) : date;
    }

    private createGridFilter(key: string): GridFilter {
        return new GridFilter(key, this.gridFilterFactory.create())
    }
}
