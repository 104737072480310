export class PartyLookup {
    constructor(public readonly code: string, public readonly displayText: string) {}
    static create(code: string, displayText: string): PartyLookup {
        return new PartyLookup(code, displayText);
    }

    /**
     * 
     * @param code string | null
     * @param displayText string
     * @returns PartyLookup if code is not null, otherwise null
     */
    static createIfValid(code: string | null, displayText: string): PartyLookup | null {
        return code ? PartyLookup.create(code, displayText) : null;
    }
}
