import { DynamicColumnResolver } from '../../dynamic-column-resolver';
import { Column } from '@port-line-up/shared/util';
import { User } from '@port-line-up/shared/data-access';

export class ExcelUploadViewGacUserResolver extends DynamicColumnResolver {
    constructor(private readonly user: User) {
        super();
    }

    override canResolve(): boolean {
        return this.user.isGacUser;
    }

    override resolve(): Column[] {
        return [
            {
                name: 'selectAll',
                title: 'Select All',
                width: '128',
            },
            {
                name: 'importStatus',
                title: 'Import Status',
                width: '135',
            },
            {
                name: 'importAs',
                title: 'Import As',
                width: '128',
            },
            {
                name: 'importMessage',
                title: 'Import Message',
                width: '256',
            },
            {
                name: 'vesselName',
                title: 'Vessel Name',
                width: '256',
            },
            {
                name: 'imoNumber',
                title: 'Imo Number',
                width: '192',
            },
            {
                name: 'vesselType',
                title: 'Vessel Type',
                width: '200',
            },
            {
                name: 'voyageNumber',
                title: 'Voyage Number',
                width: '200',
            },
            {
                name: 'vesselStatus',
                title: 'Status',
                width: '230',
            },
            {
                name: 'portName',
                title: 'Port',
                width: '192',
            },
            {
                name: 'party',
                title: 'Party',
                width: '192',
            },
            {
                name: 'terminalName',
                title: 'Terminal',
                width: '144',
            },
            {
                name: 'berthName',
                title: 'Berth',
                width: '128',
            },
            {
                name: 'berthPriority',
                title: 'Berth Priority',
                width: '128',
            },
            {
                name: 'cargoTypeName',
                title: 'Cargo',
                width: '128',
            },
            {
                name: 'cargoDescription',
                title: 'Cargo Description',
                width: '200',
            },
            {
                name: 'cargoRate',
                title: 'Cargo Rate',
                width: '160',
            },
            {
                name: 'cargoRateUOM',
                title: 'UOM (CR)',
                width: '128',
            },
            {
                name: 'operation',
                title: 'Operation',
                width: '128',
            },
            {
                name: 'quantity',
                title: 'Quantity',
                width: '128',
            },
            {
                name: 'quantityUOM',
                title: 'UOM (QT)',
                width: '128',
            },
            {
                name: 'etaAta',
                title: 'ETA / ATA',
                width: '208',
            },
            {
                name: 'etbAtb',
                title: 'ETB / ATB',
                width: '208',
            },
            {
                name: 'eccAcc',
                title: 'ECC / ACC',
                width: '208',
            },
            {
                name: 'etcAtc',
                title: 'ETC / ATC',
                width: '208',
            },
            {
                name: 'etdAtd',
                title: 'ETD / ATD',
                width: '208',
            },
            {
                name: 'notes',
                title: 'Notes',
                width: '192',
            },
            {
                name: 'dwt',
                title: 'Dwt',
                width: '192',
            },
            {
                name: 'nextDestinationNote',
                title: 'Next Destination',
                width: '192',
            },
        ];
    }
}
