import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_ENVIRONMENT } from '@port-line-up/shared/util';
import { EmailPreview, SendEmail, SharedLinkInfo, SharedViewResponse } from '../models';

@Injectable({ providedIn: 'root' })
export class SharedViewService {
    private readonly url = `${this.appEnvironment.baseApiUrl}/sharedLink`;

    constructor(private readonly http: HttpClient, @Inject(APP_ENVIRONMENT)
    private readonly appEnvironment: any) {}

    generateLink(request: FormData): Observable<SharedLinkInfo> {
        return this.http.post<SharedLinkInfo>(`${this.url}/generate`, request);
    }

    getActive(id: string, token: string): Observable<SharedViewResponse> {
        const headers = {'Authorization': `Bearer ${token}`};
        return this.http.get<SharedViewResponse>(`${this.url}/active?id=${id}`, {headers});
    }

    getSailed(id: string, token: string): Observable<SharedViewResponse> {
        const headers = {'Authorization': `Bearer ${token}`};
        return this.http.get<SharedViewResponse>(`${this.url}/sailed?id=${id}`, {headers});
    }

    getEmailPreview(url: string): Observable<EmailPreview> {
        return this.http.get<EmailPreview>(`${this.url}/email/preview?url=${url}`);
    }

    sendEmail(model: SendEmail): Observable<void> {
        return this.http.post<void>(`${this.url}/email/send`, model);
    }
}
