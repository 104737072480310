import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WINDOW } from '../tokens';
import { IFrameMessage } from '../models';
import { APP_ENVIRONMENT } from "@port-line-up/shared/util";

@Injectable({ providedIn: 'root' })
export class IFrameService implements OnDestroy {
    private readonly portalMessageSubject = new BehaviorSubject<IFrameMessage | null>(null);
    public portalMessage$ = this.portalMessageSubject.asObservable();

    private readonly loadFinishedMessage = 'pluLoaded';

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(@Inject(WINDOW) private readonly window: Window, @Inject(APP_ENVIRONMENT) private readonly appEnvironment: any) {}

    ngOnDestroy(): void {
        window.removeEventListener('message', this.handleParentMessage);
    }

    init(): void {
        this.notifyParentSiteThatLoadFinished();
        this.listenToParentMessages();
    }

    private notifyParentSiteThatLoadFinished(): void {
        this.window.parent.postMessage(this.loadFinishedMessage, this.appEnvironment.portalUrl);
    }

    private listenToParentMessages(): void {
        window.addEventListener('message', this.handleParentMessage);
    }

    private readonly handleParentMessage = (event: MessageEvent) => {
        if (event.origin === this.appEnvironment.portalUrl) {
            this.portalMessageSubject.next(event.data);
        }
    };
}
