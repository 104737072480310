import { Pipe, PipeTransform, inject } from '@angular/core';
import { HoursService } from '@port-line-up/shared/util';

// This pipe transforms total hours into the remaining hours after full days are extracted.
@Pipe({
  name: 'pluHoursToRemainingHoursPipe',
  standalone: true,
})

export class PluHoursToRemainingHoursPipe implements PipeTransform {
  private readonly hoursService = inject(HoursService);

  transform(value: number): number | null {
    return this.hoursService.getHours(value);
  }
}