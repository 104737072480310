import { Injectable } from '@angular/core';
import { BerthDelayService, PortCall, UserStateService } from '@port-line-up/shared/data-access';
import { PortCallExcelExport } from '@port-line-up/shared/util';
import { first } from 'rxjs';

import { ExcelColumnsGacUserResolver } from './resolvers/gac-user.resolver';
import { ExcelColumnsDefaultResolver } from './resolvers/default.resolver';
import { ExcelColumnsAdminResolver } from './resolvers/admin.resolver';
import { ExcelColumnsResolver } from './excel-columns-resolver';

@Injectable({providedIn: 'root'})
export class ExcelColumnsResolverService {
    private resolvers: ExcelColumnsResolver[] = [];
    constructor(userStateService: UserStateService, private readonly berthDelayService: BerthDelayService) {
        userStateService.user$.pipe(first()).subscribe((user) => {
            if (!user) {
                this.resolvers = [new ExcelColumnsDefaultResolver(this.berthDelayService)];
                return;
            }

            this.resolvers = [new ExcelColumnsGacUserResolver(user, this.berthDelayService), new ExcelColumnsAdminResolver(user, this.berthDelayService), new ExcelColumnsDefaultResolver(this.berthDelayService)];
        });
    }

    resolve(portCalls: PortCall[]): PortCallExcelExport[] {
        return (this.resolvers.find((r) => r.canResolve()) as ExcelColumnsResolver).resolve(portCalls);
    }
}
