import { AfterViewInit, DestroyRef, Directive, ElementRef, inject, Input, Renderer2 } from '@angular/core';
import { withLatestFrom } from 'rxjs';
import { ApplyChangesStatus, DataUpdateState, DataUpdateStore } from '../../services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({ selector: '[pluRowAdded]', standalone: true })
export class RowAddedDirective implements AfterViewInit {
    @Input('pluRowAdded') id!: string;

    private readonly destroyRef = inject(DestroyRef);
    constructor(private readonly dataUpdateStore: DataUpdateStore<DataUpdateState>, private readonly el: ElementRef, private readonly renderer: Renderer2) {}

    ngAfterViewInit(): void {
        this.dataUpdateStore.applyChanges$
            .pipe(withLatestFrom(this.dataUpdateStore.addedPortCalls$, this.dataUpdateStore.changedPortCalls$), takeUntilDestroyed(this.destroyRef))
            .subscribe(([apply, addedPortCalls, changedPortCalls]) => {
                if (apply.status === ApplyChangesStatus.NothingToApply || changedPortCalls.has(this.id)) {
                    return;
                }

                if (addedPortCalls.has(this.id)) {
                    this.setClass();
                } else {
                    this.removeClass();
                }
            });
    }

    private setClass(): void {
        this.renderer.addClass(this.el.nativeElement, 'datatable-presentation-row-added');
        this.renderer.addClass(this.el.nativeElement, 'ease-in-out');
        this.renderer.addClass(this.el.nativeElement, 'duration-300');
    }

    private removeClass(): void {
        this.renderer.removeClass(this.el.nativeElement, 'datatable-presentation-row-added');
        this.renderer.addClass(this.el.nativeElement, 'inherit');
    }
}
