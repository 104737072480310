import { DestroyRef, inject, Injectable } from '@angular/core';
import { PortChangesDelta, SignalRPortUpdateService } from '@port-line-up/shared/data-access';
import { DataUpdateState, DataUpdateStore } from './data-update.store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
export class SignalRDataUpdateService {
    private readonly destroyRef = inject(DestroyRef);

    constructor(private readonly signalRService: SignalRPortUpdateService, private readonly store: DataUpdateStore<DataUpdateState>) {}

    subscribeToPortUpdate() {
        this.signalRService.onPortUpdate$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data: PortChangesDelta | null) => {
            if (!data) {
                return;
            }

            const portCallChanged = data.portCallChanges;

            if (portCallChanged.edited?.length > 0) {
                this.store.setChangedPortCalls(portCallChanged.edited);
                this.store.replaceEditedPortCalls(portCallChanged.edited);
            }

            if (portCallChanged.added?.length > 0) {
                this.store.setAddedPortCalls(portCallChanged.added);
                this.store.addAddedPortCalls(portCallChanged.added);
            }

            if (portCallChanged.deleted?.length > 0) {
                this.store.setDeletedPortCalls(portCallChanged.deleted);
                this.store.deleteDeletedPortCalls(portCallChanged.deleted);
            }

            if (portCallChanged.sailed?.length > 0) {
                this.store.setSailedPortCallChanges(portCallChanged.sailed);
                this.store.deleteSailedPortCalls(portCallChanged.sailed);
            }

            this.store.setLastUpdate();
        });
    }

}
