import { Renderer2, AfterViewInit, Directive, Input, inject, DestroyRef } from '@angular/core';
import { distinctUntilChanged, fromEvent } from 'rxjs';
import { Table } from 'primeng/table';
import { ScrollableContainerService, SliderService } from '../../services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
@Directive({
    selector: '[pluTableHeaderHorizontalScroll]',
    standalone: true
})
export class TableHeaderHorizontalScrollDirective implements AfterViewInit {
    @Input({required: true, alias: 'pluTableHeaderHorizontalScroll'}) globalHeaderHeight!: number;
    private scrollLeft = 0;
    private readonly destroyRef = inject(DestroyRef);

    constructor(private readonly table: Table,
                private readonly renderer: Renderer2,
                private readonly slideService: SliderService,
                private readonly scrollableContainerService: ScrollableContainerService) {}

    ngAfterViewInit(): void {
        this.subscribeToTableScrollLeftValueChange();
        this.listenToGlobalScrollEvent();
        this.listenToGlobalResizeEvent();
    }

    private listenToGlobalScrollEvent(): void {
        const container = this.scrollableContainerService.getContainer();
        if(!container) {
            return;
        }

        let shouldAddTableHeaderHeightToGlobalHeaderHeight = false;

        fromEvent(container, 'scroll').pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged()).subscribe((_: Event): void => {
            const tableHeaderEl = this.table.tableHeaderViewChild?.nativeElement;

            const distanceTableFromPageTop = this.table.el.nativeElement.getBoundingClientRect().top;

            const headerHeight = this.globalHeaderHeight + (shouldAddTableHeaderHeightToGlobalHeaderHeight ? tableHeaderEl.offsetHeight : 0);

            const shouldSetTableHeaderPositionToBeFixed = distanceTableFromPageTop <= headerHeight;

            if (distanceTableFromPageTop === 0) {
                return;
            }

            if (shouldSetTableHeaderPositionToBeFixed) {

                this.setStyleToTableHeader(tableHeaderEl);
                if (this.table.tableHeaderViewChild) {
                    this.table.tableHeaderViewChild.nativeElement.scrollLeft = this.scrollLeft;
                }
                shouldAddTableHeaderHeightToGlobalHeaderHeight = true;
            } else {
                this.removeStyleFromTableHeader(tableHeaderEl);
                shouldAddTableHeaderHeightToGlobalHeaderHeight = false;
            }
        });
    }

    private listenToGlobalResizeEvent(): void {
        const container = this.scrollableContainerService.getContainer();
        if(!container) {
            return;
        }
        fromEvent(container, 'resize').pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged()).subscribe((_) => {
            const tableHeaderEl = this.table.tableHeaderViewChild?.nativeElement;
            const tableEl = this.table.el.nativeElement;
            this.renderer.setStyle(tableHeaderEl, 'width', tableEl.offsetWidth + 'px')
        });
    }

    private subscribeToTableScrollLeftValueChange(): void {
        this.slideService.tableScrollLeft$.pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged()).subscribe((scrollLeft) => {
            if (typeof scrollLeft === "number" && this.scrollLeft !== scrollLeft) {
                this.scrollLeft = scrollLeft;
                if(this.table.tableHeaderViewChild) {
                    this.table.tableHeaderViewChild.nativeElement.scrollLeft = scrollLeft;
                }
            }
        });
    }

    private setStyleToTableHeader(tableHeaderEl: HTMLElement): void {
        const tableEl = this.table.el.nativeElement;
        this.renderer.setStyle(tableHeaderEl, 'position', 'fixed');
        this.renderer.setStyle(tableHeaderEl, 'top', this.globalHeaderHeight+'px');
        this.renderer.setStyle(tableHeaderEl, 'width', tableEl.offsetWidth + 'px');
        this.renderer.setStyle(tableHeaderEl, 'overflow', 'hidden');
        this.renderer.setStyle(tableHeaderEl, 'z-index', 50);
    }

    private removeStyleFromTableHeader(tableHeaderEl: HTMLElement): void {
        this.renderer.removeStyle(tableHeaderEl, 'position');
        this.renderer.removeStyle(tableHeaderEl, 'top');
        this.renderer.removeStyle(tableHeaderEl, 'width');
        this.renderer.removeStyle(tableHeaderEl, 'overflow');
        this.renderer.removeStyle(tableHeaderEl, 'z-index');
    }
}
