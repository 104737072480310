import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_ENVIRONMENT } from '@port-line-up/shared/util';
import { Observable } from 'rxjs';
import { Report } from '../models/report';

@Injectable({ providedIn: 'root' })
export class ReportService {
    private readonly baseUrl = `${this.appEnvironment.baseApiUrl}/report`;

    constructor(private readonly http: HttpClient, @Inject(APP_ENVIRONMENT)
    private readonly appEnvironment: any) {}

    getReport(): Observable<Report> {
        return this.http.get<Report>(`${this.baseUrl}`);
    }
}
