import { Injectable } from '@angular/core';
import { DateService } from '@port-line-up/shared/util';
import { DeletedPortCalls } from '../models/changed-port-calls';

@Injectable()
export class DeletedDataService {
    constructor(private readonly date: DateService) {}

    add(id: string, addedMap: DeletedPortCalls): void {
        if (addedMap.has(id)) {
            return;
        }

        addedMap.set(id, this.date.now());
    }

    remove(deletedMap: DeletedPortCalls, after: number): DeletedPortCalls {
        const seconds = after * 1000;
        const currentTime = this.date.now();

        for (const [id, time] of deletedMap.entries()) {
            const shouldRemove = time + seconds < currentTime;
            if (shouldRemove) {
                deletedMap.delete(id);
            }
        }

        return deletedMap;
    }
}
