import { Inject, Injectable } from '@angular/core';
import { Feature } from 'geojson';
import { AnyLayer, GeoJSONSourceRaw, MapboxOptions, PopupOptions } from 'mapbox-gl';
import { VesselLocation } from '../models/vessel-location';
import { APP_ENVIRONMENT } from '@port-line-up/shared/util';
import { VesselStatusIconResolver } from '@port-line-up/shared/data-access';
import { PortCallMapTimeIndicator } from '../models/port-call-map-time-indicator';
import { PluDatePipe } from '@port-line-up/shared/ui/pipes';

@Injectable()
export class VesselTrackingMapOptionsService {
    public vesselsSourceName = 'vesselsSource';
    public vesselsLayerName = 'vesselsLayer';
    public focusedVesselSourceName = 'focusedVesselSource';
    public focusedVesselSymbolLayerName = 'focusedVesselSymbolLayer';
    public focusedVesselSymbolAltLayerName = 'focusedVesselAltSymbolLayer';

    constructor(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        @Inject(APP_ENVIRONMENT)
        private readonly appEnvironment: any,
        private readonly vesselStatusIconResolver: VesselStatusIconResolver,
        private readonly pluDatePipe: PluDatePipe
    ) {}

    createMapboxOptions(portLongitude: number, portLatitude: number, mapContainerId: string): MapboxOptions {
        return {
            container: mapContainerId,
            style: 'mapbox://styles/mapbox/outdoors-v12',
            zoom: 11,
            center: [portLongitude, portLatitude],
            accessToken: this.appEnvironment.mapbox.accessToken,
        } as MapboxOptions;
    }

    getVesselsSource(locations: VesselLocation[]): GeoJSONSourceRaw {
        return {
            type: 'geojson',
            generateId: true,
            data: {
                type: 'FeatureCollection',
                features: locations.map((l) => {
                    return {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [l.lng ? +l.lng : 0, l.lat ? +l.lat : 0],  
                        },
                        properties: {
                            portCallId: l.portCallId,
                            title: l.imo,
                            imo: l.imo,
                            rotation: l.heading,
                            name: l.name,
                            status: l.status ?? '',
                            portCallMapTimeIndicator: l.portCallMapTimeIndicator,
                            cargoTypeName: l?.cargoType?.map(c => c?.displayText) ?? 'N/A',
                            cargoQuantity: l?.cargoQuantity.map(c => c) ?? '',
                            cargoQuantityUomName: l?.cargoQuantityUomName?.map(c => c) ?? '',
                        },
                    };
                }),
            },
        };
    }

    getFocusedVesselLayers(): AnyLayer[] {
        return [
            {
                id: this.focusedVesselSymbolAltLayerName,
                type: 'symbol',
                source: this.focusedVesselSourceName,
                layout: {
                    'icon-image': 'red-alt-0',
                    'icon-rotate': ['get', 'rotation'],
                    'icon-allow-overlap': true,
                    'icon-size': 1.3,
                },
                paint: {
                    'icon-opacity': ['case', ['==', ['get', 'opacity'], 1], ['case', ['to-boolean', ['feature-state', 'additional']], 1, 0], 0],
                },
            },
            {
                id: this.focusedVesselSymbolLayerName,
                type: 'symbol',
                source: this.focusedVesselSourceName,
                layout: {
                    'icon-image': 'gray-0',
                    'icon-rotate': ['get', 'rotation'],
                    'icon-allow-overlap': true,
                    'icon-size': 1.3,
                },
                paint: {
                    'icon-opacity': ['case', ['==', ['get', 'opacity'], 1], ['case', ['to-boolean', ['feature-state', 'additional']], 0, 1], 0],
                },
            },
        ];
    }

    getFocusedVesselSource(v: VesselLocation | null): GeoJSONSourceRaw {
        return {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        id: 1,
                        geometry: {
                            type: 'Point',
                            coordinates: v ? [v.lng ? +v.lng : 0, v.lat ? +v.lat : 0] : [0, 0],
                        },
                        properties: {
                            portCallId: v?.portCallId,
                            opacity: v ? 1 : 0,
                            title: v?.imo,
                            imo: v?.imo,
                            rotation: v?.heading ?? 0,
                            name: v?.name,
                            status: v?.status ?? '',
                            portCallMapTimeIndicator: v?.portCallMapTimeIndicator,
                            cargoTypeName: v?.cargoType?.map(c => c?.displayText) ?? 'N/A',
                            cargoQuantity: v?.cargoQuantity.map(c => c) ?? '',
                            cargoQuantityUomName: v?.cargoQuantityUomName?.map(c => c) ?? '',
                        },
                    },
                ],
            },
        };
    }

    getVesselsLayer(): AnyLayer {
        return {
            id: this.vesselsLayerName,
            type: 'symbol',
            source: this.vesselsSourceName,
            layout: {
                'icon-image': 'red-0',
                'icon-rotate': ['get', 'rotation'],
                'icon-allow-overlap': true,
            },
        };
    }

    getVesselTooltipHtml(feature: Feature, coordinates: Array<number>): string {
        const icon = this.vesselStatusIconResolver.resolve(feature.properties?.['status']);
        const iconSrc = icon ? `${this.appEnvironment.baseUrl}/assets/images/${icon}` : '';

        let portCallMapTimeIndicator = new PortCallMapTimeIndicator(null, null, null);
        if(feature.properties?.['portCallMapTimeIndicator']) {
            portCallMapTimeIndicator =  JSON.parse(feature.properties?.['portCallMapTimeIndicator']);
        }
        const hasCargo = feature.properties?.['cargoTypeName'] !== 'N/A';

        return `
                <table class='-m-[10px]'>
                    <thead style="background-color: #315BBB">
                        <tr>
                            <th colspan="2" class='text-white text-left px-2 py-3'>${feature.properties?.['name']}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class='font-medium border-b-gray-200 border-b-[1px] mx-2 py-2 flex justify-between gap-16'>
                            <th class='text-left text-gray-600'>IMO</th>
                            <td class='text-right text-gray-800'>${feature.properties?.['imo']}</td>
                        </tr>
                        <tr class='font-medium border-b-gray-200 border-b-[1px] mx-2 py-2 flex justify-between gap-16'>
                            <th class='text-left text-gray-600'>Status</th>
                            <td class='text-right text-gray-800 flex gap-2'>${
                                iconSrc
                                    ? `<span><img src="${iconSrc}"/></span><span>${feature.properties?.['status']}</span>`
                                    : `<span>${feature.properties?.['status']}</span>`
                            }</td>
                        </tr>
                        <tr class='font-medium border-b-gray-200 border-b-[1px] mx-2 py-2 flex justify-between gap-16'>
                            <th class='text-left'>
                                <div class="px-1 h-[20px] text-sm text-center font-medium uppercase text-white rounded flex items-center justify-between min-w-[54px] pt-0.5 ${portCallMapTimeIndicator.customClass} ng-star-inserted">
                                    ${portCallMapTimeIndicator.labelHtml}
                                </div>
                            </th>
                            <td class='text-right text-gray-800'>${portCallMapTimeIndicator.value ? this.pluDatePipe.transform(portCallMapTimeIndicator.value) : ''}</td>
                        </tr>
                        <tr class='font-medium border-b-gray-200 border-b-[1px] mx-2 py-2 flex justify-between gap-16'>
                            <th class='text-left text-gray-600'>Cargo</th>
                            <td class='text-right ${hasCargo ? 'text-gray-800' : 'text-gray-600'}'>
                                ${this.formatCargoInformation(feature.properties?.['cargoTypeName'], feature.properties?.['cargoQuantity'], feature.properties?.['cargoQuantityUomName']) }
                            </td>
                        </tr>
                        <tr class='font-medium mx-2 pt-2 flex justify-between gap-16'>
                            <th class='text-left text-gray-600'>Lat</th>
                            <td class='text-right text-gray-800'>${coordinates[1]}</td>
                        </tr>
                        <tr class='font-medium mx-2 pb-2 flex justify-between gap-16'>
                            <th class='text-left text-gray-600'>Lng</th>
                            <td class='text-right text-gray-800'>${coordinates[0]}</td>
                        </tr>
                    </tbody>
                </table>
        `;
    }

    private formatCargoInformation(cargoType: string, cargoQuantity: string, cargoQuantityUomName: string): string {
        const cargos: string[] = JSON.parse(cargoType);
        const quantity: number[] = JSON.parse(cargoQuantity);
        const quantityUomNames: string[] = JSON.parse(cargoQuantityUomName);
        return cargos.length === 0 ? 'N/A' : `${cargos[0]} ${quantity[0] ?? ''} ${quantityUomNames[0] ?? ''}`
    }
    
    getVesselTooltipOptions(): PopupOptions {
        return {
            closeButton: false,
            closeOnClick: false,
            offset: 10,
            maxWidth: '35em',
            className: 'vessel-popup',
        };
    }
}
