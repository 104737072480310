import { Pipe, PipeTransform, inject } from '@angular/core';
import { HoursService } from '@port-line-up/shared/util';

@Pipe({
  name: 'pluHoursToDaysPipe',
  standalone: true,
})

export class PluHoursToDaysPipe implements PipeTransform {
  private readonly hoursService = inject(HoursService);

  transform(value: number): number | null {
    return this.hoursService.getDays(value);
  }
}