import { Injectable } from '@angular/core';
import { Column } from '@port-line-up/shared/util';
import { EditViewColumnResolver } from './edit-view-columns/edit-view-column.resolver';
import { PresentationViewColumnResolver } from './presentation-view-columns/presentation-view-column.resolver';
import { ExcelUploadViewColumnResolver } from './excel-upload-view-columns/excel-upload-view-column.resolver';
import { GroupByViewColumnResolver } from './group-by-view-columns/group-by-view-column.resolver';
import { FleetViewColumnResolver } from './fleet-view-columns/fleet-view-column.resolver';

@Injectable({ providedIn: 'root' })
export class DynamicColumnFacadeService {
    constructor(
        private readonly editViewColumnResolver: EditViewColumnResolver,
        private readonly presentationViewColumnResolver: PresentationViewColumnResolver,
        private readonly excelUploadViewColumnResolver: ExcelUploadViewColumnResolver,
        private readonly groupByViewColumnResolver: GroupByViewColumnResolver,
        private readonly fleetViewColumnResolver: FleetViewColumnResolver
    ) {}

    getGridColumnsForEditView(): Column[] {
        return this.editViewColumnResolver.resolve();
    }

    getGridColumnsForPresentationView(): Column[] {
        return this.presentationViewColumnResolver.resolve();
    }

    getGridColumnsForExcelUpload(): Column[] {
        return this.excelUploadViewColumnResolver.resolve();
    }

    getGridColumnsForGroupByView(): Column[] {
        return this.groupByViewColumnResolver.resolve();
    }

    getGridColumnsForFleetView(): Column[] {
        return this.fleetViewColumnResolver.resolve();
    }
}
