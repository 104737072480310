import { Injectable } from '@angular/core';
import { PropertyNameExcelHeaderMapping } from '@port-line-up/shared/util';
import { ExcelUploadMappingsDataResolver } from './excel-upload-mapping-data/excel-upload-mappings-data.resolver';

@Injectable({ providedIn: 'root' })
export class ExcelUploadMappingDataFacadeService {
    constructor(private readonly excelUploadMappingDataResolver: ExcelUploadMappingsDataResolver) {}

    getAvailablePropertyNamesForMapping(): PropertyNameExcelHeaderMapping[] {
        return this.excelUploadMappingDataResolver.resolve();
    }
}
